body .HubspotForm {

  margin: 2rem auto;

  ul {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
  }

  // overwrite .Prose li
  li {
    padding-left: 0 !important;
    &:before {
      display: none !important;
    }
  }

  label {
    font-weight: bold;
  }

  .hs-form-field {
    @extend .form-group;
  }

  fieldset {
    max-width: unset;
    &.form-columns-1 {
      .input {
        margin-right: 0;
      }
      .hs-input {
        width: 100%;
      }
    }
    &.form-columns-2 {
      @include make-row();
      display: block;
      .input {
        margin-right: 0;
      }
      .hs-form-field {
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(sm) {
          @include make-col(6);
        }
      }
    }
  }

  .hs-input[type="text"],
  .hs-input[type="email"],
  select.hs-input,
  textarea.hs-input {
    @extend .form-control;
    @extend .form-control-sm;
  }

  .inputs-list {
    label {
      font-weight: 400;
    }
  }

  .hs-richtext {
    opacity: .7;
    a {
      text-decoration: underline;
      color: inherit !important;
    }
  }

  .hs-error-msg,
  .hs-error-msgs {
    font-weight: 400;
    color: color("red") !important;
  }

  .hs-form-booleancheckbox,
  .hs-form-checkbox {
    label {
      display: inline-flex;
    }
    input {
      margin-top: .3em;
    }
    input + span {
      display: inline-block;
      margin-left: .5em;
    }
  }

  input[type="submit"] {
    @extend .btn;
    @include button();
    border: 1px solid currentColor;
    // @extend .Button--primary;
  }
}
