.TextWImage {
}

.TextWImage-container {
  @include make-container();
  @include make-container-max-widths();
}

.TextWImage-wrapper {
  .TextWImageList--overlayBackground & {
    background: rgba(#000, .15);
  }
  .TextWImageList--overlayBackground.Background--default & {
    background: rgba(#fff, .05);
  }
  .TextWImageList--overlayBackground.Background--light &,
  .TextWImageList--overlayBackground.Background--lighter & {
    background: rgba(#000, .07);
  }
}

.TextWImage-inner {
  display: flex;
  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }
  @include media-breakpoint-up(sm) {
    align-items: center;
  }
}

.TextWImage-figureWrapper,
.TextWImage-content {
  width: 100%;
  @include media-breakpoint-up(sm) {
  }
}

.TextWImage-figureWrapper {
  @include media-breakpoint-down(xs) {
    margin-bottom: 2rem;
  }
  @include media-breakpoint-up(sm) {
    flex: 0 0 50%;
  }
  .TextWImageList-item:nth-child(odd) & {
    @include media-breakpoint-up(sm) {
      order: 2;
    }
  }
}

.TextWImage-figure {
  @include imagePlaceholderColor();
}

.TextWImage-content {
  .TextWImageList--overlayBackground & {
    @include res-prop("padding-right", $grid-container-paddings);
    @include res-prop("padding-left", $grid-container-paddings);
    @include res-prop("padding-top", $grid-container-paddings, (xs: 0, sm: 1, lg: 1, xl: 1));
    @include res-prop("padding-bottom", $grid-container-paddings, (xs: 0, sm: 1, lg: 1, xl: 1));
    @include media-breakpoint-down(xs) {
      padding-bottom: 2rem;
    }
  }
  .TextWImageList:not(.TextWImageList--overlayBackground) .TextWImageList-item:nth-child(even) & {
    @include res-prop("padding-left", $grid-container-paddings, (xs: 0, sm: 1, lg: 1, xl: 1));
  }
  .TextWImageList:not(.TextWImageList--overlayBackground) .TextWImageList-item:nth-child(odd) & {
    @include res-prop("padding-right", $grid-container-paddings, (xs: 0, sm: 1, lg: 1, xl: 1));
  }
  .TextWImageList:not(.TextWImageList--overlayBackground) {

  }
}

.TextWImage-body {
  @include media-breakpoint-up(sm) {
    max-width: 30rem;
    margin: auto;
    .TextWImageList--narrowText & {
      max-width: 16rem;
    }
  }
}

.TextWImage-image {
  width: 100%;
  height: auto;
  @extend %lazyloaded;
}

.TextWImage-image--mobile {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.TextWImage-image--mobile +
.TextWImage-image--default {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}
