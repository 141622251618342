.AttributesImages,
.Prose .AttributesImages {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-top: .5rem;
  .p-ItemPreview & {
    margin-top: 0;
  }
  img {
    margin: 0 1rem 1rem 0;
    display: block;
    max-width: none;
    height: 20px;
    .p-ItemPreview & {
      height: 16px;
      margin-top: 1rem;
      margin-bottom: 0;
    }
  }
}
