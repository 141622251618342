.p-Filters {
  font-weight: 400;
  @include font-size-small();
  @include res-prop(("margin-top", "margin-bottom"), $block-spacer);
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  .custom-control-label {
    padding-top: 0.2em;
    @include media-breakpoint-down(xs) {
      padding-top: 0.1em;
    }
  }
}

.p-Filters-wrapper {
  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zindex-product-filters;
    transform: translateX(110%);
    transition: transform 0s .4s;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transform: translateX(110%);
      opacity: 0;
      transition: opacity .4s 0s, transform 0s .4s;
      background-color: rgba(#000, .3);
    }
    .p-Filters.is-open & {
      transform: translateX(0);
      transition: transform 0s 0s;
      &:before {
        opacity: 1;
        transform: translateX(0);
        transition: opacity .4s 0s, transform 0s 0s;
      }
    }
  }
}

.p-Filters-container {
  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 0;
    width: 85%;
    height: 100%;
    right: 0;
    background-color: #fff;
    transform: translateX(110%);
    transition: transform .4s;
    @include res-prop("padding-left", $grid-gutter-widths);
    @include res-prop("padding-right", $grid-gutter-widths);
    .p-Filters.is-open & {
      transform: translateX(0);
      transition: transform .4s;
    }
  }
}

.p-Filters-panel {
  @include media-breakpoint-down(sm) {
    position: absolute;
    top: 7rem;
    left: 0;
    right: 0;
    bottom: 0;
    @include res-prop("padding-left", $grid-gutter-widths);
    @include res-prop("padding-right", $grid-gutter-widths);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
}

.p-Filters-close {
  position: absolute;
  @include res-prop("top", $grid-gutter-widths);
  @include res-prop("right", $grid-gutter-widths);
  appearance: none;
  padding: 0;
  border: none;
  background: none;
  width: 1rem;
  height: 1rem;
  &:before,
  &:after {
    content: '';
    background-color: currentColor;
    position: absolute;
    transition: inherit;
    top: 50%;
    left: 50%;
  }
  &:before {
    height: 2px;
    width: 100%;
    transform: translate(-50%, -50%) rotate(45deg);
  }
  &:after {
    height: 100%;
    width: 2px;
    transform: translate(-50%, -50%) rotate(225deg);
  }

  @include media-breakpoint-up(md) {
    display: none;
  }
}

.p-Filters-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1rem;
  @include media-breakpoint-down(sm) {
    margin: 0;
    height: 7rem;
    align-items: flex-end;
    padding-bottom: 1rem;
    border-bottom: 1px solid currentColor;
  }
}

.p-Filters-title {
  // font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0;
}

.p-Filters-reset {
  color: $color-link;
  text-decoration: underline;
}

.p-Filters-select {
  margin-bottom: 1.5rem;
}

.p-Filters-list {
  border-bottom: 1px solid currentColor;
  @include media-breakpoint-down(sm) {
    margin-bottom: 2rem !important;
  }
}

.p-Filters-type {
  border-top: 1px solid currentColor;
  @include media-breakpoint-down(sm) {
    &:first-child {
      border: none;
    }
  }
  // padding-bottom: .5rem;
}

.p-Filters-count {
  // padding-left: 0.4em;
}

.p-Filters-toggle {
  cursor: pointer;
  display: flex;
  padding: .75em 0;
  align-items: center;
  font-weight: 500;
  .Icon {
    margin-left: auto;
  }
  &.is-open .Icon {
    transform: rotate(180deg);
  }
}

.p-Filters-item {
  padding: .2em 0;
  display: flex;
  &:last-child {
    padding-bottom: 1em;
  }
}

.p-Filters-link {
  color: currentColor;
  cursor: default;
  label {
    cursor: pointer;
  }
  input[disabled],
  input[disabled] + label {
    pointer-events: none;
  }
}
