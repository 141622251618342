.DwnList-container {
  @include revealOpacity();
}

.DwnList-title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 3rem;
}

.DwnList-item {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
}

.Dwn-ext {
  font-weight: 400;
}
