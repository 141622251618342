.p-ItemPreview {
  background: $color-background-lighter;
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  @include reveal();
  transition-delay: inherit;
}

.p-ItemPreview-link {
  display: block;
  flex: 0 0 100%;
  position: relative;
  text-decoration: none !important;
  color: $color-text-inverted !important;
}

.p-ItemPreview-figure {
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

.p-ItemPreview-image {
  @extend %lazyloaded;
  position: absolute;
  $padding: 0%;
  top: $padding;
  left: $padding;
  right: $padding;
  bottom: $padding;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .desktop & {
    transform: scale(1.01) rotate(0.1deg);
    transition: transform .6s, opacity .6s;
  }
  .desktop a:hover & {
    transform: scale(1.1) rotate(0.1deg);
  }
}

.p-ItemPreview-body {
  @include res-prop("padding", $grid-gutter-widths, 1);
  @include loupesWebsite() {
    position: relative;
    .p-ItemPreview-title {
      margin-right: 32px;
    }
    .AttributesImages {
      @include res-prop(("right", "top"), $grid-gutter-widths, 1);
      position: absolute;
      img {
        width: 20px;
        height: auto;
        margin: -10px 0 0;
        &:not(:first-child) {
          display: none;
        }
      }
    }
  }
}

.p-ItemPreview-title {
  @extend .h5;
  font-weight: 400;
  // text-transform: uppercase;
  margin-bottom: 1rem;
}

.p-ItemPreview-specs {
  // font-weight: 400;
  margin: 0;
  line-height: 1.3;
}

.p-ItemPreview-dealers {
  cursor: pointer;
  position: absolute;
  top: 0;
  padding: 6px;
  margin-right: 12px;
  right: 24px + 6px; // icon width + WishlistToggle padding
  display: block;

  .Icon {
    color: color("gray7");
  }
}

.p-ItemPreview-wishlist {
  position: absolute;
  top: 0;
  right: 0;
}
