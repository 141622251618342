.p-SubCategoryHeader {
  @include roomForHeaderWhenIsFirstChild();
  @include res-prop("padding-top", $section-spacer, 0.5);
}

.p-SubCategoryHeader-image {
  height: 20vw;
  min-height: 80px;
  max-height: 220px;
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: contain;
  @extend %lazyloaded;
}
