.p-FamilyHeader {
  @include roomForHeaderWhenIsFirstChild("margin-top");
  @include res-prop("padding-top", $block-spacer);
  @include res-prop("padding-bottom", $block-spacer);
}

.p-FamilyHeader-colImage {
  @include media-breakpoint-down(xs) {
    // padding-bottom: 2rem;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    margin-top: 3rem;
  }
  @include media-breakpoint-up(md) {
    @include make-col(7);
    // margin-top: 5rem;
  }
}

.p-FamilyHeader-colContent {
  @include revealOpacity(false, ".p-FamilyHeader");
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
  @include media-breakpoint-up(md) {
    @include make-col(5);
    padding-top: 1rem;
  }
}

.p-FamilyHeader-figure {
  @include media-breakpoint-up(xl) {
    margin-left: -8%;
  }
}

.p-FamilyHeader-image {
  width: 100%;
  height: auto;
  @extend %lazyloaded;
}


.p-FamilyHeader-breadcrumb {
  @extend .p-Nav-breadcrumb;
  // increase specificity
  .p-FamilyHeader & {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 4rem;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 5rem;
    }
    li:after {
      opacity: .5;
    }
    a {
      opacity: .5;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.p-FamilyHeader-title {
  @include ms-respond(font-size, $p-FamilyHeader-title-scale);
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
  @include loupesWebsite() {
    @include font-serif();
  }
}

.p-FamilyHeader-claim {
  font-weight: 300;
  margin: 0;
  margin-top: .5rem;
}

.p-FamilyHeader-download {
  margin-top: 3rem;
  margin-bottom: 0;
}

.p-FamilyHeader-description {
  margin-top: 3rem;
}
