.Dwn {
  padding: .75em .2em;
  border-top: 1px solid #ccc;
  display: block;
  color: $color-text !important;
  display: flex;
  align-items: center;
  text-decoration: none !important;
  position: relative;
  .DwnList-item:last-child & {
    border-bottom: 1px solid #ccc;
  }
  @include media-breakpoint-up(sm) {
    .DwnList-item:nth-last-child(2) & {
      border-bottom: 1px solid #ccc;
    }
  }
  .desktop &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: 0;
    transition: opacity .3s;
  }
  .desktop &:hover:before {
    opacity: .1;
  }
}

.Dwn-label {
  flex: 1 1 100%;
  margin-right: 1rem;
}

.Dwn-ext {
  text-transform: uppercase;
  font-size: 80%;
  flex: 0 0 2rem;
  text-align: right;
}
