// .swiper-container {
//   @include media-breakpoint-down(xs) {
//     padding-bottom: 28px;
//   }
// }

.swiper-pagination {
  font-size: 0;
  display: flex;
  justify-content: center;
}
.swiper-pagination-bullet {
  border-radius: 0;
  width: 2px;
  height: 12px;
  background: currentColor;
  opacity: .3;
  transition: opacity .2s, transform .2s;
  @include media-breakpoint-up(sm) {
    // width: 26px;
    // height: 3px;
  }
}

.swiper-pagination-bullet-active {
  // background: $color-link;
  opacity: .9;
  transform: translateY(-6px);
}

.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 0;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 3px 0 0;
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}

// .swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
//   // transform: none;
// }

.swiper-scrollbar {
  background: none;
  border-radius: 0;
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: relative;
  left: unset;
  bottom: unset;
  height: 2px;
  margin: 2rem 0 0 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: currentColor;
    opacity: .3;

  }
}

.swiper-scrollbar-drag {
  background: currentColor;
}
