.SiteFooter {
  background: $color-background;
  color: $color-text;
  font-size: calc(1rem - 2px);
  position: relative;
  padding: 1rem 0;
  @include media-breakpoint-up(sm) {
    padding: 2rem 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 3rem 0;
  }
  a, button {
    color: inherit;
    transition: color .2s;
    &:hover {
      color: $color-link;
    }
  }
}

.SiteFooter-row {
  position: relative;
  padding: 1rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid color("gray85");
  &:last-child {
    border: none;
  }
  @include media-breakpoint-down(xs) {
    flex-wrap: wrap;
  }
}

.SiteFooter-colophon {
  margin-right: 1rem;
  @include media-breakpoint-down(xs) {
    margin-bottom: 1rem;
  }
  p {
    margin: 0;
  }
}


.SiteFooter-service {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  li:not(:last-child) {
    margin-right: 1rem;
  }
}

.SiteFooter-backToTop {
  cursor: pointer;
  outline: none !important;
  border: none;
  padding: 0;
  background: none;
}

.SiteFooter-backToTop--top {
  margin-left: auto;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.SiteFooter-backToTop--bottom {
  @include media-breakpoint-down(xs) {
    display: none;
  }
  position: absolute;
  top: 0;
  right: 0;
  width: 4rem;
  height: 100%;
  border-left: 1px solid color("gray85");
  .Icon {
    transition: transform .3s;
  }
  .desktop &:hover {
    .Icon {
      transform: translateY(-4px);
    }
  }
}

.SiteFooter-country {
  display: inline-flex;
  align-items: center;
  margin: .5rem 0;
  .Icon {
    margin-right: .5rem;
  }
  @include media-breakpoint-up(sm) {
    position: relative;
    right: 5rem;
  }
}
