.p-FamilyFeatures {
  background-color: $color-background-lighter;
  color: $color-text-inverted;
  @include res-prop("padding-top", $section-spacer);
  @include res-prop("padding-bottom", $section-spacer);
}

.p-FamilyFeatures-sectionTitle {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: $letter-spacing;
  max-width: 20em;
  margin: auto;
  @include res-prop("margin-bottom", $block-spacer);
  @include reveal();
}

.p-FamilyFeatures-image {
  width: 100%;
  height: auto;
  @extend %lazyloaded;
}

.p-FamilyFeatures-colImage {
  @include res-prop("margin-bottom", $block-spacer);
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
}
.p-FamilyFeatures-colContent {
  @include reveal();
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
  @include media-breakpoint-up(md) {
    @include make-col(5);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
    @include make-col-offset(1);
  }
}

.p-FamilyFeatures-title {
  text-transform: uppercase;
  font-weight: 300;
  margin: 0;
}

.p-FamilyFeatures-claim {
  font-weight: 300;
  margin: 0;
  margin-top: .5rem;
}

.p-FamilyFeatures-weight {
  text-align: right;
  @extend .h6;
  font-weight: 300;
  margin: 0;
}

@include loupesWebsite('.p-FamilyFeatures') {
  text-align: center;
  h5 {
    margin-bottom: 1rem;
    text-transform: uppercase;
  }
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0 0 2rem;
    color: rgba($color-text-inverted,.6);
  }
  table {
    width: 100%;
  }
  th {
    font-weight: 400;
    padding-bottom: .5rem;
    vertical-align: bottom;
    img {
      display: block;
      margin: auto;
      width: 20px;
    }
  }
  td {
    border-top: 1px solid currentColor;
    color: rgba($color-text-inverted,.6);
    padding: .2rem 0;
  }

  .row {
    justify-content: space-around;
  }

  .p-FamilyFeatures-colContent {
    @include media-breakpoint-up(md) {
      @include make-col(5);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(4);
      @include make-col-offset(0);
    }
  }
}
