.ArticlePreview {
  @include reveal(false, false);
}

.ArticlePreview-link {
  color: currentColor !important;
  text-decoration: none !important;
}

.ArticlePreview-figure {
  position: relative;
  margin-bottom: 1rem;
  overflow: hidden;
  background-color: rgba(#000, .08);
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    opacity: 0;
    transition: opacity .6s;
    a:hover & {
      opacity: .15;
    }
  }
}

.ArticlePreview-imageSize {
  width: 100%;
  height: auto;
}

.ArticlePreview-imageBg {
  // display: none;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: -1px;
  right: -1px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  @extend %lazyloaded;
}

.ArticlePreview-meta {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  line-height: 1.3;
}

.ArticlePreview-date {
  margin-right: .5em;
}

.ArticlePreview-category {
  text-transform: uppercase;
  font-weight: 500;
  margin-right: .5em;
}

.ArticlePreview-title {
  margin: 0;
  @extend .h2;
  font-weight: 300;
}

.ArticlePreview-titleText {
  // @include underline-background();
}

.ArticlePreview-content {
  transition: height .6s;
  .ArticlePreview--focus & {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    @include grid-spacer("p", "x");
    @include grid-spacer("p", "y", 2);
    @include media-breakpoint-up(sm) {
      top: auto;
      bottom: 0;
      transform: none;
    }
  }
}

