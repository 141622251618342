.Header {
  position: relative;
  overflow: hidden;
}

.Header-slide {
  display: flex;
}

.Header-slide--inverted {
  .Prose {
    @extend .Prose--inverted;
  }
}

.Header-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  min-height: 70vh;
  .Header--height100 & {
    min-height: 100vh;
  }
  align-items: flex-end;
  @media (orientation: portrait) {
    min-height: 80vh;
  }
  &:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 1 / 2.8 * 100%;
    @media (orientation: portrait) {
      padding-top: 1 / 1 * 100%;
    }
  }
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


.Header-image,
.Header-video,
.Header-video video {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.Header-video {
  overflow: hidden;
}

.Header-image {
  background-position: center;
  background-size: cover;
}

.Header-image,
.Header-video video {
  @extend %lazyloaded;
}

.Header-image--portrait {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.Header-image--portrait +
.Header-image--landscape {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.Header-container {
  @include make-container();
  @include make-container-max-widths();
}

.Header-row {
  @include make-row();
}

.Header-content {
  @include make-col-ready();
  @include res-prop("padding-bottom", $grid-gutter-widths, (xs: 2.5, sm: 2, lg: 2, xl: 2));
  @include grid-spacer("p", "t", 4);
  @include media-breakpoint-up(sm) {
    @include make-col(8);
    max-width: 36rem;
  }
  .desktop & {
    opacity: 0;
    transform: translateY(1rem);
    transition: transform 0s linear .3s, opacity .3s;
  }
  .desktop .swiper-slide-active & {
    opacity: 1;
    transform: translateY(0);
    transition: transform 1.2s, opacity .3s;
  }
  .Header-slide--text50xs & {
    @include media-breakpoint-down(xs) {
      max-width: 55%;
    }
  }
}

.Header-navigation {
  display: none;
  .desktop .Header.is-ready & {
    display: block;
  }
}

.Header-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-100%);
  transition: transform .6s cubic-bezier("easeInCubic");
  &[data-header-next] {
    right: 0;
    transform: translateY(-50%) translateX(100%);
    .show-next & {
      transition-timing-function: cubic-bezier("easeOutQuint");
      transform: translateY(-50%) translateX(-50%);
    }
  }
  &[data-header-prev] {
    .show-prev & {
      transition-timing-function: cubic-bezier("easeOutQuint");
      transform: translateY(-50%) translateX(50%);
    }
  }
}

.Header-pagination {
  @include make-container();
  @include res-prop("bottom", $grid-gutter-widths, (xs: 1, sm: 2, lg: 2, xl: 2), true);
  &.swiper-pagination {
    @include media-breakpoint-up(sm) {
      justify-content: flex-end;
    }
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 2px;
    margin-right: 2px;
    @include media-breakpoint-up(md) {
      width: 28px;
    }
  }
  .swiper-pagination-bullet-active {
    transform: none;
  }
}

.Header-scrollHint {
  position: absolute;
  @include res-prop("bottom", $grid-gutter-widths, (xs: 1, sm: 1, lg: 1, xl: 1));
  left: 50%;
  transform: translate(-50%, 50%);
  z-index: 100;
  display: none;
  .Header--height100.is-ready & {
    @include media-breakpoint-up(sm) {
      display: block;
    }
  }
  .Icon {
    transform: rotate(90deg);
  }
}

.Header-scrollHint,
.Header-pagination {
  transition: color .2s;
}

.Header--inverted {
  .Header-scrollHint,
  .Header-pagination {
    color: $color-text-inverted;
  }
}
