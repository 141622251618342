.EnhancedSelect {

}

.EnhancedSelect--secondary {
  .dropdown-toggle,
  .dropdown-menu {
    background-color: color("gray96");
  }
}
