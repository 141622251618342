.custom-control-label {
  &:before {
    background: none;
    border: 1px solid $custom-control-indicator-bg;
  }
  &:after {
    border: 1px solid transparent;
  }
}

.custom-radio .custom-control-label:after {
  border-radius: 50%;
}

.custom-control-input:checked:not(:disabled)~.custom-control-label:after {
  // border-color: #1f7d3e;
}
