@mixin button-style($bg-color, $border-color, $mask-color, $text-color, $icon-color) {
  color: $text-color !important;
  &:before {
    background: $bg-color;
    border-color: $border-color;
  }
  &:after {
    background: $mask-color;
  }
  .Icon {
    color: $icon-color;
  }
}

.Button {
  @extend .btn;
  @include button();
  &.is-loading {
    .Icon {
      opacity: 0;
    }
    &:after {
      content: '';
      position: absolute;
      z-index: 3;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transform-origin: 50% 50%;
      width: 1rem;
      height: 1rem;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
      border-radius: 50%;
      border: 2px solid currentColor;
      border-left-color: transparent;
      border-radius: 50%;
      animation: rotate .6s linear infinite;
      opacity: 1;
      background-color: transparent;
    }
  }
}

.Button--block {
  display: block;
  // width: 100%;
  text-align: center;
  &.Button--w-icon {
    text-align: left;
  }
}

.Button--xs-block {
  @include media-breakpoint-down(xs) {
    display: block;
    width: 100%;
    text-align: center;
    &.Button--w-icon {
      text-align: left;
    }
    & + & {
      margin-top: 1rem;
    }
  }
  @include media-breakpoint-up(sm) {
    & + & {
      margin-left: .5rem;
    }
  }
}

.Button--lg,
.Prose .Button:not(.Button--w-icon) {
  padding: calc(#{$btn-padding-y} + #{$btn-border-width}) calc(#{$btn-padding-x * 2} + #{$btn-border-width});
}

.Button--w-icon,
.Button--icon {
  .Icon {
    position: absolute;
    right: $btn-padding-x;
    top: 50%;
    transform: translateY(-50%);
  }
}

.Button--icon {
  height: $font-size-base * $btn-line-height;
  width: $font-size-base * $btn-line-height * 0.5;
  box-sizing: content-box;
  &.Button--lg {
    width: $font-size-base * $btn-line-height;
    padding: calc(#{$btn-padding-y * 1.5} + #{$btn-border-width});
    &:after {
      box-sizing: border-box;
      width: calc(100% + #{$btn-padding-y * 3} + #{$btn-border-width * 1.5} + #{$btn-border-width} + (#{$font-size-base} * #{$btn-line-height}));
    }
  }
  &.Button--xl {
    width: $font-size-base * $btn-line-height;
    padding: calc(#{$btn-padding-y * 2} + #{$btn-border-width});
    &:after {
      box-sizing: border-box;
      width: calc(100% + #{$btn-padding-y * 4} + #{$btn-border-width * 2} + #{$btn-border-width} + (#{$font-size-base} * #{$btn-line-height}));
    }
  }
  .Icon {
    right: 50%;
    transform: translate(50%, -50%);
  }
  // padding-right: $btn-padding-x*3;
}

.Button--w-icon {
  padding-right: $btn-padding-x*3;
}

.Button--primary {
  color: $color-text-inverted;
  background: $color-text;
  &:hover {
    color: $color-text-inverted;
  }
  &:before {
    display: none;
  }
  .Background--light &,
  .Background--lighter & {
    color: $color-text;
    background: $color-text-inverted;
    &:hover {
      color: $color-text;
    }
  }
}

.Button--w-logo {
  img {
    height: 1.5rem;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5rem;
  }
}

/*
$color-background: color("gray7");
$color-background-dark: color("gray14");
$color-background-light: color("gray96");
$color-background-lighter: color("white");
*/

.Button--primaryInverted {
  background: tint($color-background, 14%);
  &:before {
    opacity: 0;
  }
  .Background--dark & {
    background: tint($color-background-dark, 12%);
  }
  .Background--gray & {
    background: tint($color-background-dark, 12%);
    color: $color-text;
  }
  .Background--green & {
    background: $color-background;
  }
  .Background--light &,
  .Background--lighter & {
    color: $color-text-inverted;
    background: $color-background-lighter;
    &:hover {
      color: $color-text-inverted;
    }
  }
  .Background--lighter & {
    background: $color-background-light;
  }
}

.Button--rounded {
  border-radius: 50%;
  &:before {
    border-width: 2px;
    border-radius: 50%;
    top: -1px;
    bottom: -1px;
    right: -1px;
    left: -1px;
  }
}

.Button--secondary {
  // todo
  // @include button-style(transparent, rgba(color("gray96"), .6), color("gray96"), #fff, #fff);
  // &:after {
  //   opacity: .1;
  // }
}

.Background--light .Button--secondary,
.Background--light .ButtonSecondary,
body.cke_editable .ButtonSecondary,
.Prose.Prose--inverted .ButtonSecondary {
  // todo
  // @include button-style(transparent, rgba(color("gray7"), .6), color("gray7"), color("gray7"), color("gray7"));
}

.Background--green .Button--secondary {
  // @include button-style(transparent, rgba(color("gray7"), .5), color("gray7"), color("gray7"), color("gray7"));
}

.Background--lighter .Button--secondary,
.Background--lighter .ButtonSecondary {
  // todo
  // @include button-style(color("gray96"), rgba(color("gray7"), .6), color("gray7"), color("gray7"), color("gray7"));
}
