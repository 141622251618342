.dropdown-toggle {
  position: relative;
  border-color: rgba(color("gray7"), .2);
  background: #fff;

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: $input-btn-padding-x;
    transform: translateY(-50%);
    width: 18px;
    height: 18px;
    margin: 0 !important;
    background-image: url(svg-encode($svg-chevron-small-down));
    background-size: 100% 100%;
    .bootstrap-select.show & {
      transform: translateY(-50%) rotate(180deg);
    }
    @include media-breakpoint-up(sm) {
      width: 24px;
      height: 24px;
    }
  }
}

.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}
