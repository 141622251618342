.p-FamilyPreview {
  background: #000;
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
  position: relative;
  @include reveal();
  transition-delay: inherit;
  opacity: 1 !important;
  &:after {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    background-color: $color-background-dark;
    // transition: transform 1.2s cubic-bezier("easeOutCubic"), opacity 1.2s;
    transition: inherit;
    opacity: 1;
  }
  &.is-shown:after {
    opacity: 0;
  }
}

.p-FamilyPreview-link {
  display: block;
  flex: 0 0 100%;
  position: relative;
  text-decoration: none !important;
}

.p-FamilyPreview-figure {
  height: 0;
  padding-bottom: 100%;
  position: relative;
  overflow: hidden;
}

.p-FamilyPreview-image {
  @extend %lazyloaded;
  position: absolute;
  top: 8%;
  left: 8%;
  right: 8%;
  bottom: 8%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  .desktop & {
    transform: scale(1.01) rotate(0.1deg);
    transition: opacity .6s, transform .6s;
  }
  .desktop a:hover & {
    transform: scale(1.1) rotate(0.1deg);
  }
}

.p-FamilyPreview-body {
  @include res-prop("padding-left", $grid-gutter-widths, 1);
  @include res-prop("padding-right", $grid-gutter-widths, 1);
  @include res-prop("padding-top", $grid-gutter-widths, 0.5);
  @include res-prop("padding-bottom", $grid-gutter-widths, 0.5);
}

.p-FamilyPreview-title {
  @extend .h5;
  font-weight: 400;
  margin-bottom: 0;
  color: $color-text;
  @include loupesWebsite() {
    @include font-serif();
    .font-light {
      display: block;
    }
  }
}
