.demobtn {
  &:after {
    content: '>';
    display: inline-block;
    margin-left: .2em;
  }
}

.demo-col {
  padding: 16px;
  margin-bottom: 16px;
  background-color: #ddd;
}

.demo-nav {
  display: flex;
  justify-content: center;
  flex-direction: column;
  img {
    margin: auto;
    display: none;
    width: 100%;
    height: auto;
  }
  @include media-breakpoint-only(xs) {
    .xs {
      display: block;
    }
  }
  @include media-breakpoint-only(sm) {
    .sm {
      display: block;
    }
  }

  @include media-breakpoint-only(md) {
    .md {
      display: block;
    }
  }

  @include media-breakpoint-only(lg) {
    .lg {
      display: block;
    }
  }

   @include media-breakpoint-only(xl) {
    .xl {
      display: block;
    }
  }
}
