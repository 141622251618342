.Background {
  background: $color-background;

  &--darker {
    background: $color-background; // default
  }

  &--light {
    background: $color-background-light;
  }

  &--lighter {
    background: $color-background-lighter;
  }

  &--dark {
    background: $color-background-dark;
  }

  &--gray {
    background: $color-background-gray;
  }

  &--green {
    background: color("green");
    // background: linear-gradient(lighten(color("green"), 10), color("green"));
  }

  &--light,
  &--lighter,
  &--gray {
    color: $color-text-inverted;
  }

}
