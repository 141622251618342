.Gallery {
  // @include block-spacer();
  overflow: hidden;
  // @include roomForHeaderWhenIsFirstChild();
  img {
    width: 100%;
    height: auto;
  }
  .swiper-container {
    // overflow: visible;
  }
}

.Gallery-title {
  @extend .h3;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: $letter-spacing;
  max-width: 20em;
  margin: auto;
  @include res-prop("margin-bottom", $block-spacer);
}

.Gallery--product.Section {
  padding-top: 0;
  @include res-prop("padding-bottom", $block-spacer);
}

.Gallery-container {
  @include make-container();
  @include make-container-max-widths();
}


.Gallery-row {
  @include make-row();
}

.Gallery-content {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(sm) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  // @include media-breakpoint-up(md) {
  //   @include make-col(9);
  // }
}

.Gallery-slide {
  @include imagePlaceholderColor();
}

.Gallery-image {
  height: 0;
  padding-bottom: 9 / 16 * 100%;
  background-size: cover;
  background-position: center;
  @extend %lazyloaded;
  cursor: zoom-in;
  .Gallery--noZoom & {
    cursor: unset;
  }
}

.Gallery-image-print {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  .Gallery-slide:not(:first-child) & {
    display: none;
  }
}

.Gallery-navigation {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.Gallery-button {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-100%, -50%);
  color: currentColor;
  background: none;
  padding: 0;
  border: none;
  outline: none !important;
  cursor: pointer;
  &[data-gallery-next] {
    left: auto;
    right: 0;
    transform: translate(100%, -50%);
  }
  &.is-disabled {
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
  }
}

.Gallery-pagination {
  position: static;
  padding-top: 1rem;
  @include media-breakpoint-up(sm) {
    padding-top: 1.5rem;
  }
}

.Gallery-container,
.Gallery-navigation {
  // opacity: 0;
  // transition: opacity .6s;
  // .Gallery.is-shown.is-ready & {
  //   opacity: 1;
  // }
}

.Gallery-container {
  @include reveal(false, ".Gallery");
}

/* used only in JS */
.Gallery--noZoom {}




