.LoadMore {
  @include res-prop("margin-top", $block-spacer, 1);
  @include reveal();
  text-align: center;
  &.is-loading {
    pointer-events: none;
  }
}

.LoadMore-link {
  .Icon--loader {
    display: none;
  }
}

.LoadMore.is-loading {



    .Icon--arrow-down {
      display: none;
    }
    .Icon--loader {
      display: block;
      .Icon-svg use {
        transform-origin: 50% 50%;
        animation-name: load-more-spin;
        animation-duration: 1s;
        animation-iteration-count: infinite;
      }
    }

}

@keyframes load-more-spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(360deg);
    }
}
