.DealerInfo {
  @include res-prop("padding", $grid-gutter-widths);

  height: 100%;
  font-weight: 400;
  background: $color-background-lighter;
  color: $color-text-inverted;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .Icon-svg {
    position: relative;
    margin-right: 0.5rem;

    @include media-breakpoint-up(sm) {
      width: 18px !important;
      height: 18px !important;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

  [data-title] {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $headings-line-height;

    @include ms-respond(font-size, $h5-font-size-scale);
  }

  [data-address] {
    margin-bottom: 0.75rem !important;
  }

  [data-address],
  [data-mail],
  [data-telephone],
  [data-ecommerce],
  [data-navigation] {
    line-height: 1.25;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    margin-bottom: 0;
    margin-top: 0.25rem;

    [href] {
      text-decoration: underline;
      color: currentColor;
    }
  }

  [data-distance] {
    font-size: 15px;
    color: color("gray58");
    margin-top: 0.75rem;
  }

  [data-tags] {
    @include res-prop("padding-top", $grid-gutter-widths, 0.75);

    margin-top: auto;
    display: flex;
    flex-wrap: wrap;

    & > * {
      font-size: 13px;
      margin-top: 0.5rem;
      background: color("gray85");
      line-height: 1;
      padding: 0.3em 0.6em;
      border-radius: 999px;
      font-family: $font-family-secondary;
      font-weight: 400;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}
