html {
  @include font-smoothing();
  @include res-prop("font-size", $font-size-root);
  -webkit-tap-highlight-color: rgba($color-link, 0.5);
}

body {
  &:not(.cke_editable) {
    overscroll-behavior-y: none;
  }
}

figure {
  margin: 0;
}

blockquote {
  margin: 0;
}

svg {
}

.page-transitioning-in {
  .barba-container + .barba-container {
    display: none;
  }
}

#print {
  display: none;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

