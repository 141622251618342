.ArticleList {
  @include res-prop("padding-bottom", $block-spacer);
  // // because .FeaturedArticles:before is a fixed patch to protect the logo
  // position: relative;
  // z-index: 4;
  background-color: $color-background-light;
  color: $color-text-inverted;
}

.ArticleList-container {
  @include make-container();
  @include make-container-max-widths();
}

.ArticleList-row {
  @include make-row();
  align-items: flex-end;
  // justify-content: center;
}

.ArticleList-item {
  @include make-col-ready();
  @include res-prop("margin-bottom", $grid-gutter-widths, 2);
  &:nth-child(1) {
    margin-top: 0;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    &:nth-child(2) {
      margin-top: 0;
    }
    &:nth-child(5n+3),
    &:nth-child(5n+4),
    &:nth-child(5n+5) {
      @include make-col(4);
    }
  }
  /*
  @include media-breakpoint-up(lg) {
    @include make-col(4);
    &:nth-child(3) {
      margin-top: 0;
    }
    &:nth-child(7n+4),
    &:nth-child(7n+5),
    &:nth-child(7n+6),
    &:nth-child(7n+7) {
      @include make-col(3);
    }
  }
  */
}
