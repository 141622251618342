.Section {
  position: relative; // to mask the Spotlight
  // @include SectionPadding();
  @include res-prop("padding-top", $section-spacer);
  @include res-prop("padding-bottom", $section-spacer);
  &.Background--default + &.Background--default,
  &.Background--darker + &.Background--darker,
  &.Background--dark + &.Background--dark,
  &.Background--light + &.Background--light,
  &.Background--lighter + &.Background--lighter {
    padding-top: 0;
  }
}

.Section--subItem:not(:last-child) {
  @include res-prop("padding-bottom", $block-spacer, 1);
  // @include SectionItemPadding();
}
