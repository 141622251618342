.TextOverImage {
  position: relative;
  &.Section {
    padding-top: 0;
    @include media-breakpoint-up(sm) {
      padding-bottom: 0px;
    }
  }
}

.TextOverImage-container {
  position: relative;
  @include make-container();
  @include make-container-max-widths();
}

.TextOverImage-wrapper {
  @include make-row();
}

.TextOverImage-content {
  @include make-col-ready();
  @include SectionPadding();
  @include reveal();
  @include media-breakpoint-down(xs) {
    padding: 0;
  }
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    min-height: 80vh;
    display: flex;
    align-items: center;
    .TextOverImage--height100 & {
      min-height: 100vh;
    }
    .TextOverImage--alignRight & {
      @include make-col-offset(6);
    }
  }
  @include media-breakpoint-up(md) {
    @include make-col(5);
    .TextOverImage--alignRight & {
      @include make-col-offset(7);
    }
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
    @include make-col-offset(1);
  }
}

.TextOverImage-body {
}

.TextOverImage-figure {
  @extend %lazyloaded;
  @include media-breakpoint-up(sm) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
  }
  @include media-breakpoint-down(xs) {
    background: none !important;
  }
}

.TextOverImage-image {
  margin-bottom: 2rem;
  width: 100%;
  height: auto;
  @extend %lazyloaded;
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.TextOverImage-image--mobile {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.TextOverImage-image--mobile +
.TextOverImage-image--default {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}
