.Strip-container {
  @include res-prop("padding-top", $section-spacer, 0.5);
  @include res-prop("padding-bottom", $section-spacer, 0.5);
  @include media-breakpoint-up(sm) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.Strip-heading {
  @include media-breakpoint-down(xs) {
    margin-bottom: $headings-margin-bottom * 2;
  }
}

.Strip-caption {
  margin: 0;
}

.Strip-title {
  text-transform: uppercase;
  margin: 0;
}

.Strip-container {
  @include revealOpacity(false, ".Strip");
}
