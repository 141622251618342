.p-SubCategoryPreview {
  background: $color-background-lighter;
  color: $color-text-inverted;
  @include media-breakpoint-up(sm) {
    display: flex;
  }
}

.p-SubCategoryPreview-figure {
  @include media-breakpoint-up(sm) {
    flex: 0 0 50%;
    .p-SubCategoryList-item:nth-child(odd) & {
      order: 2;
    }
  }
}

.p-SubCategoryPreview-image {
  @extend %lazyloaded;
  height: 0;
  padding-bottom: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.p-SubCategoryPreview-content {
  background: $color-background-light;
  @include loupesWebsite() {
    background: $color-background-gray;
  }
  @include media-breakpoint-up(sm) {
    flex: 0 0 50%;
    display: flex;
    align-items: center;
  }
}

.p-SubCategoryPreview-body {
  padding: map_get($grid-container-paddings, xs);
  @include media-breakpoint-up(md) {
    padding: 15%;

  }
}

.p-SubCategoryPreview-category {
  @extend .h5;
  font-weight: 300;
  margin-bottom: 0;
}

.p-SubCategoryPreview-subcategory {
  @extend .h2;
  font-weight: 400;
  @include loupesWebsite() {
    @include font-serif();
    letter-spacing: 0;
  }
  a {
    color: inherit;
    transition: color .3s;
    &:hover {
      text-decoration: none;
      color: $color-link;
      @include loupesWebsite() {
        color: #fff;
      }
    }
  }
}
