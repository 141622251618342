body.cke_editable {
  @extend .Prose;
  @extend .Background--lighter;
  margin: 1rem;
  background: unset;
  color: unset;
  hr {
    visibility: visible !important;
  }
}

.cke_panel_container body {
  background-color: unset;
  color: unset;
}
