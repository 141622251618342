.SearchMenu {
  color: $color-text-inverted;
  background-color: $color-background;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: $zindex-site-header + 1;
  pointer-events: none;
  opacity: 0;
  transition: opacity .3s;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    @include res-prop("height", $site-header-height);
    background-color: #000;
  }
  .show-search & {
    pointer-events: auto;
    opacity: 1;
  }

  form {
    @include grid-spacer("m", t, 1);
    position: relative;
    border-bottom: 1px solid currentColor;
    input[type="text"] {
      width: 100%;
      font-size: 1.2rem;
      padding: .2em 32px .2em 0;
      background-color: transparent;
      border: none;
      appearance: none;
      outline: none !important;
    }
    button[type="submit"] {
      position: absolute;
      padding: 0;
      background-color: transparent;
      border: none;
      appearance: none;
      outline: none !important;
      right: 0;
      cursor: pointer;
    }
  }

}

.SearchMenu-wrapper {
  position: absolute;
  @include res-prop("top", $site-header-height);
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
}

.SearchMenu-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  // min-height: 100vh;
  // flex-basis: 100%;
  @include media-breakpoint-down(xs) {
    // flex-direction: column-reverse;
  }
}

.SearchMenu-section {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem 0;
  @include media-breakpoint-up(sm) {
    // flex: 1 0 50%;
  }
  &:last-child {
    background-color: $color-background-light;
    @include loupesWebsite() {
      background-color: $color-background-gray;
    }
  }
  &:first-child {
    @include media-breakpoint-down(xs) {
      order: 2;
    }
  }
}

.SearchMenu-heading {
  color: $color-link;
  margin-bottom: 1rem;
  @include loupesWebsite() {
    @include font-serif();
    color: inherit;
  }
}


.SearchMenu-links {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  li {
    @include grid-spacer("m", t, 1);
    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }
    @include media-breakpoint-up(md) {
      @include make-col(4);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(3);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(1,5);
    }
  }
  a {
    background-color: $color-background-light;
  }
}
