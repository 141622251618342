.Wishlist {
  @include roomForHeaderWhenIsFirstChild("margin-top");
  @include res-prop("padding-top", $block-spacer);
  @include res-prop("padding-bottom", $block-spacer);
}

.Wishlist-header {
  margin-bottom: 2rem;
}

.Wishlist-header-cta {
  @include media-breakpoint-up(sm) {
    text-align: right;
  }
  @include media-breakpoint-down(xs) {
    padding-top: 1rem;
  }
  .Wishlist--empty & {
    display: none;
  }
}

.Wishlist-list {
  > ul {
    border-bottom: 1px solid rgba(#000, 0.2);
    list-style-type: none;
    margin: 0;
    padding: 0;
    > li {
      border-top: 1px solid rgba(#000, 0.2);
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-top: 1rem;
    }
  }
  .Wishlist--empty & {
    display: none;
  }
}

.Wishlist-item {
  &.is-loading {
    opacity: 0.5;
    pointer-events: none;
  }
  display: flex;
  a {
    text-decoration: none;
  }
  @include media-breakpoint-up(sm) {
    @include make-row();
  }
}

.Wishlist-item-imageCol {
  @include media-breakpoint-down(xs) {
    width: 33%;
    max-width: 130px;
    min-width: 100px;
  }
  @include media-breakpoint-up(sm) {
    @include make-col-ready();
    @include make-col(1.5);
  }
}

.Wishlist-item-image {
  width: 100%;
  padding-bottom: 100%;
  background: center/cover no-repeat;
}

.Wishlist-item-image-print {
  display: none;
}

.Wishlist-item-mainCol {
  flex-grow: 1;
  display: flex;
  @include media-breakpoint-up(sm) {
    @include make-col-ready();
    flex-basis: auto;
    width: auto;
    flex-direction: column;
    justify-content: center;
  }
}

.Wishlist-item-mainContent {
  @include media-breakpoint-down(xs) {
    padding-left: map_get($grid-gutter-widths, "xs");
    flex: 0 0 100%;
    display: flex;
    flex-direction: column;
  }
  @include media-breakpoint-up(sm) {
    @include make-row();
    justify-content: space-between;
    align-items: center;
  }
}


.Wishlist-item-titleCol {
  p {
    margin: 0;
  }
  a {
    color: $color-text-inverted !important;
  }
  h1 {
    margin-bottom: 0;
  }
  @include media-breakpoint-down(xs) {
    margin-bottom: auto;
  }
  @include media-breakpoint-up(sm) {
    @include make-col-ready();
    @include make-col(7);
  }
  @include media-breakpoint-up(md) {
    @include make-col(8);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(9);
  }
}

.Wishlist-item-download {
  a {
    text-decoration: underline;
  }
}

.Wishlist-item-actionCol {
  margin-left: auto;
  font-size: 0;
}

.Wishlist-item-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media-breakpoint-up(sm) {
    @include make-col-ready();
    @include make-col(5);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
  }
  @include media-breakpoint-up(md) {
    @include make-col(3);
  }
}

.Wishlist-item-inputCol {
  // @include media-breakpoint-up(sm) {
  //   @include make-col-ready();
  //   @include make-col(5);
  // }
}

.Wishlist-item-actionCol {
  // @include media-breakpoint-up(sm) {
  //   @include make-col-ready();
  //   @include make-col(5);
  // }
}


.Wishlist-input {
  -moz-appearance: textfield;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.Wishlist-item-inputGroup {
  position: relative;
  button {
    position: absolute;
    appearance: none;
    font-size: 0;
    padding: 0;
    border: 0;
    background: none;
    outline: none;
    cursor: pointer;
    top: 50%;
    right: $input-padding-y * 0.8;
    &[data-action="up"] {
      transform: translateY(-80%) rotate(180deg);
    }
    &[data-action="down"] {
      transform: translateY(-10%);
    }
  }
}

.Wishlist-input {
  width: 6rem;
}

.Wishlist-item-remove {
  appearance: none;
  font-size: 0;
  padding: 0;
  border: 0;
  background: none;
  outline: none !important;
  cursor: pointer;
}

.Wishlist-mainCta {
  margin: 4rem 0;
  text-align: center;
  .Wishlist--empty & {
    display: none;
  }
}

.Wishlist-item-quantity {
  display: none;
}

.Wishlist-content--empty {
  min-height: 40vh;
}

.Wishlist:not(.Wishlist--empty) .Wishlist-content--empty,
.Wishlist--empty .Wishlist-content--notEmpty {
  display: none;
}
