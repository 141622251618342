.Prose {

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  h1, h2, h3, h4, h5, h6 {
    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: $headings-margin-bottom * -0.5;
    }
  }

  p + h1,
  p + h2,
  p + h3,
  p + h4,
  p + h5,
  p + h6 {
    margin-top: $paragraph-margin-bottom * 2;
  }

  figcaption {
    margin-top: .5em;
    // @include font-size-small();
  }

  .TextformatterVideoEmbed {
    padding-top: 0 !important;
    margin: 2rem auto;
  }

  img {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 100%;
    height: auto;
  }

  figure {
    margin: 2rem auto;
    img {
      margin: auto;
    }
  }

  a:not(.Button) {
    color: currentColor !important;
    text-decoration: underline;
  }

  ul, ol {
    padding-left: 0;
    margin-left: 0;
    list-style-position: inside;
  }
  ul:not(.dropdown-menu) li {
    list-style: none;
    padding-left: 1.1em;
    &:before {
      content: '>';
      display: inline-block;
      padding-right: .5em;
      margin-left: -1.1em;
    }
  }
}

.Prose--inverted {
  color: $color-text-inverted;
}
