.LanguagesDialog {
  .modal-body {
    ul {
      list-style-type: none;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 0;
      margin: 0;
      font-weight: 400;
    }
    li {
      padding: 0.25rem 0;
    }
    a {
      color: inherit;
    }
  }
}
