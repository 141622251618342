.SiteHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $zindex-site-header;
  a {
    color: inherit;
  }
  .desktop & {
    position: absolute;
  }
  @include loupesWebsite() {
    transition: color .2s linear .4s;
  }
  @include loupesWebsite("&", ".show-menu") {
    color: #000;
    transition-delay: .25s;
  }

  .mobile &,
  .tablet & {

    transition: transform .3s;

    &--unpinned {
      transform: translate3D(0,-100%,0);
    }

    &--pinned {
      transform: translate3D(0,0,0);
    }

  }

  &:before {
    position: absolute;
    background-color: $color-background;
    content: '';
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity .3s;
    // .mobile &,
    // .tablet & {
    //   opacity: 1 !important;
    // }
  }

  &.SiteHeader--pinned.SiteHeader--not-top:before {
    opacity: 1;
  }

  // FROM BASILI.CO --> TEST!
  // html.page-transitioning-out & {
  //   transition: none !important;
  //   background-color: transparent !important;
  //   * {
  //     transition: none !important;
  //   }
  // }
}

.SiteHeader-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: $zindex-site-header + 3;
  @include res-prop("height", $site-header-height);
}

.SiteHeader-brand {
  display: block;
  font-size: 0;
}

.SiteHeader-icons {
  display: flex;

  > * {
    cursor: pointer;
    position: relative;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 1rem;

      @include media-breakpoint-down(xs) {
        margin-right: 0.5rem;
      }
    }

    &[aria-pressed="true"] {
      .is-toggle {
        transform: scale(0);
        opacity: 0;
      }
      .Icon--x {
        transform: translate(-50%, -50%) scale(1);
        opacity: 1;
      }
    }
  }

  > * > span:not(.Icon) {
    font-weight: 400;
    margin-left: 0.5rem;
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  .Icon {
    transition: transform .3s;
  }

  .Icon--x {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }

  > *[data-total]:not([data-total="0"]) {
    &:after {
      content: attr(data-total);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: -0.1em;
      top: -0.2em;
      background-color: #fff;
      color: #000;
      font-size: 0.8em;
      font-weight: 500;
      height: 1.3em;
      min-width: 1.3em;
      text-align: center;
      padding: 0.2em 0.25em;
      border-radius: 10em;
    }
  }
}


.SiteHeader-logo {
  fill: currentColor;
  width: auto;
  height: 14px;
  @include media-breakpoint-up(sm) {
    height: 16px;
  }
  @include media-breakpoint-up(lg) {
    height: 20px;
  }
}

.SiteHeader-languageToggle {
  body:not(.has-languages) & {
    display: none;
  }
}
