.ImageGrid {
  // @include block-spacer();
  // @include roomForHeaderWhenIsFirstChild();
  img {
    width: 100%;
    height: auto;
  }
  a {
    cursor: zoom-in;
  }
}

.ImageGrid-container {
  @include make-container();
  @include make-container-max-widths();
}

.ImageGrid-row {
  @include make-row();
  justify-content: center;
}

.ImageGrid-item {
  @include make-col-ready();
  @include reveal();

  &:not(:first-child) {
    @include grid-spacer("m", "t", (xs: 2, sm: 1, lg: 1));
  }

  @include media-breakpoint-up(sm) {

    @include quantity-exactly(1) {
      @include make-col(10);
    }

    @include quantity-exactly(2) {
      @include make-col(6);
    }

    @include quantity-exactly(3) {
      @include make-col(4);
      &:nth-child(3) {
        margin-top: 0;
      }
    }

    @include quantity-at-least(4) {
      @include make-col(6);
    }

    @include quantity-at-least(2) {
      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
}

.ImageGrid-imageWrapper {
  @include imagePlaceholderColor();
  // background-color: $media-placeholder-color;
}

.ImageGrid-image {
  @extend %lazyloaded;
}

.ImageGrid-caption {
  margin-top: 1em;
  @include font-size-small();
}

.ImageGrid--noZoom {
  .ImageGrid-item {
    pointer-events: none;
  }
}
