$flags : (
  "ad" : "-42px 0",
  "ae" : "-462px 0",
  "af" : "0 -42px",
  "ag" : "-42px -42px",
  "ai" : "-84px 0",
  "al" : "-84px -42px",
  "am" : "0 -84px",
  "an" : "-42px -84px",
  "ao" : "-84px -84px",
  "aq" : "-126px 0",
  "ar" : "-126px -42px",
  "as" : "-126px -84px",
  "at" : "0 -126px",
  "au" : "-42px -126px",
  "aw" : "-84px -126px",
  "ax" : "-126px -126px",
  "az" : "-168px 0",
  "ba" : "-168px -42px",
  "bb" : "-168px -84px",
  "bd" : "-168px -126px",
  "be" : "0 -168px",
  "bf" : "-42px -168px",
  "bg" : "-84px -168px",
  "bh" : "-126px -168px",
  "bi" : "-168px -168px",
  "bj" : "-210px 0",
  "bl" : "-210px -42px",
  "bm" : "-210px -84px",
  "bn" : "-210px -126px",
  "bo" : "-210px -168px",
  "br" : "0 -210px",
  "bs" : "-42px -210px",
  "bt" : "-84px -210px",
  "bw" : "-126px -210px",
  "by" : "-168px -210px",
  "bz" : "-210px -210px",
  "ca" : "-252px 0",
  "cc" : "-252px -42px",
  "cd" : "-252px -84px",
  "cf" : "-252px -126px",
  "cg" : "-252px -168px",
  "ch" : "-252px -210px",
  "ci" : "0 -252px",
  "ck" : "-42px -252px",
  "cl" : "-84px -252px",
  "cm" : "-126px -252px",
  "cn" : "-168px -252px",
  "co" : "-210px -252px",
  "cr" : "-252px -252px",
  "cu" : "-294px 0",
  "cv" : "-294px -42px",
  "cw" : "-294px -84px",
  "cx" : "-294px -126px",
  "cy" : "-294px -168px",
  "cz" : "-294px -210px",
  "de" : "-294px -252px",
  "dj" : "0 -294px",
  "dk" : "-42px -294px",
  "dm" : "-84px -294px",
  "do" : "-126px -294px",
  "dz" : "-168px -294px",
  "ec" : "-210px -294px",
  "ee" : "-252px -294px",
  "eg" : "-294px -294px",
  "eh" : "-336px 0",
  "er" : "-336px -42px",
  "es" : "-336px -84px",
  "et" : "-336px -126px",
  "eu" : "-336px -168px",
  "fi" : "-336px -210px",
  "fj" : "-336px -252px",
  "fk" : "-336px -294px",
  "fm" : "0 -336px",
  "fo" : "-42px -336px",
  "fr" : "-84px -336px",
  "ga" : "-126px -336px",
  "gb" : "-168px -336px",
  "gd" : "-210px -336px",
  "ge" : "-252px -336px",
  "gg" : "-294px -336px",
  "gh" : "-336px -336px",
  "gi" : "-378px 0",
  "gl" : "-378px -42px",
  "gm" : "-378px -84px",
  "gn" : "-378px -126px",
  "gq" : "-378px -210px",
  "gp" : "-378px -168px",
  "gr" : "-378px -252px",
  "gs" : "-378px -294px",
  "gt" : "-378px -336px",
  "gu" : "0 -378px",
  "gw" : "-42px -378px",
  "gy" : "-84px -378px",
  "hk" : "-126px -378px",
  "hn" : "-168px -378px",
  "hr" : "-210px -378px",
  "ht" : "-252px -378px",
  "hu" : "-294px -378px",
  "ic" : "-336px -378px",
  "id" : "-378px -378px",
  "ie" : "-420px 0",
  "il" : "-420px -42px",
  "im" : "-420px -84px",
  "in" : "-420px -126px",
  "iq" : "-420px -168px",
  "ir" : "-420px -210px",
  "is" : "-420px -252px",
  "it" : "-420px -294px",
  "je" : "-420px -336px",
  "jm" : "-420px -378px",
  "jo" : "0 -420px",
  "jp" : "-42px -420px",
  "ke" : "-84px -420px",
  "kg" : "-126px -420px",
  "kh" : "-168px -420px",
  "ki" : "-210px -420px",
  "km" : "-252px -420px",
  "kn" : "-294px -420px",
  "kp" : "-336px -420px",
  "kr" : "-378px -420px",
  "kw" : "-420px -420px",
  "ky" : "0 0",
  "kz" : "-462px -42px",
  "la" : "-462px -84px",
  "lb" : "-462px -126px",
  "lc" : "-462px -168px",
  "li" : "-462px -210px",
  "lk" : "-462px -252px",
  "lr" : "-462px -294px",
  "ls" : "-462px -336px",
  "lt" : "-462px -378px",
  "lu" : "-462px -420px",
  "lv" : "0 -462px",
  "ly" : "-42px -462px",
  "ma" : "-84px -462px",
  "mc" : "-126px -462px",
  "md" : "-168px -462px",
  "me" : "-210px -462px",
  "mf" : "-252px -462px",
  "mg" : "-294px -462px",
  "mh" : "-336px -462px",
  "mk" : "-378px -462px",
  "ml" : "-420px -462px",
  "mm" : "-462px -462px",
  "mn" : "-504px 0",
  "mo" : "-504px -42px",
  "mp" : "-504px -84px",
  "mq" : "-504px -126px",
  "mr" : "-504px -168px",
  "ms" : "-504px -210px",
  "mt" : "-504px -252px",
  "mu" : "-504px -294px",
  "mv" : "-504px -336px",
  "mw" : "-504px -378px",
  "mx" : "-504px -420px",
  "my" : "-504px -462px",
  "mz" : "0 -504px",
  "na" : "-42px -504px",
  "nc" : "-84px -504px",
  "ne" : "-126px -504px",
  "nf" : "-168px -504px",
  "ng" : "-210px -504px",
  "ni" : "-252px -504px",
  "nl" : "-294px -504px",
  "no" : "-336px -504px",
  "np" : "-378px -504px",
  "nr" : "-420px -504px",
  "nu" : "-462px -504px",
  "nz" : "-504px -504px",
  "om" : "-546px 0",
  "pa" : "-546px -42px",
  "pe" : "-546px -84px",
  "pf" : "-546px -126px",
  "pg" : "-546px -168px",
  "ph" : "-546px -210px",
  "pk" : "-546px -252px",
  "pl" : "-546px -294px",
  "pn" : "-546px -336px",
  "pr" : "-546px -378px",
  "ps" : "-546px -420px",
  "pt" : "-546px -462px",
  "pw" : "-546px -504px",
  "py" : "0 -546px",
  "qa" : "-42px -546px",
  "ro" : "-84px -546px",
  "rs" : "-126px -546px",
  "ru" : "-168px -546px",
  "rw" : "-210px -546px",
  "sa" : "-252px -546px",
  "sb" : "-294px -546px",
  "sc" : "-336px -546px",
  "sd" : "-378px -546px",
  "se" : "-420px -546px",
  "sg" : "-462px -546px",
  "sh" : "-504px -546px",
  "si" : "-546px -546px",
  "sk" : "-588px 0",
  "sl" : "-588px -42px",
  "sm" : "-588px -84px",
  "sn" : "-588px -126px",
  "so" : "-588px -168px",
  "sr" : "-588px -210px",
  "ss" : "-588px -252px",
  "st" : "-588px -294px",
  "sv" : "-588px -336px",
  "sy" : "-588px -378px",
  "sz" : "-588px -420px",
  "tc" : "-588px -462px",
  "td" : "-588px -504px",
  "tf" : "-588px -546px",
  "tg" : "0 -588px",
  "th" : "-42px -588px",
  "tj" : "-84px -588px",
  "tk" : "-126px -588px",
  "tl" : "-168px -588px",
  "tm" : "-210px -588px",
  "tn" : "-252px -588px",
  "to" : "-294px -588px",
  "tr" : "-336px -588px",
  "tt" : "-378px -588px",
  "tv" : "-420px -588px",
  "tw" : "-462px -588px",
  "tz" : "-504px -588px",
  "ua" : "-546px -588px",
  "ug" : "-588px -588px",
  "us" : "-630px 0",
  "uy" : "-630px -42px",
  "uz" : "-630px -84px",
  "va" : "-630px -126px",
  "vc" : "-630px -168px",
  "ve" : "-630px -210px",
  "vg" : "-630px -252px",
  "vi" : "-630px -294px",
  "vn" : "-630px -336px",
  "vu" : "-630px -378px",
  "wf" : "-630px -420px",
  "ws" : "-630px -504px",
  "ye" : "-630px -546px",
  "yt" : "-630px -588px",
  "za" : "0 -630px",
  "zm" : "-42px -630px",
  "zw" : "-84px -630px",
  "ww" : "-630px -462px"
);

[class *= " Flag-"],
[class ^= "Flag-"] {
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  margin-right: 1rem;
  position: relative;
  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:before {
    background-image: url(../img/flags.png);
    background-size: 662px 662px !important;
    @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
      background-image: url(../img/flags_high.png);
    }
  }
  &:after {
    top: 6px;
    bottom: 6px;
    left: 1px;
    right: 1px;
    border: 1px solid #ededed;
    // opacity: .08;
    display: none;
    mix-blend-mode: darken;
  }
}

@each $country in map_keys($flags) {
  .Flag-#{$country}:before {
    background-position: unquote(map_get($flags, $country));
  }
}

@each $country in (ar, as, at, bg, bh, bi, bl, ca, ci, cl, cr, cu, cy, cz, dj, dk, do, dz, eg, eh, fi, fo, fr, ge, gg, gi, gl, gp, gt, hn, hr, hu, ic, id, ie, il, in, iq, ir, it, je, jo, jp, kr, kw, lb, lv, lu, mc, mf, mg, mq, mt, mx, nl, om, pa, pe, pf, ph, pk, pr, ps, qa, rs, ru, sg, si, sk, sl, sm, sv, sy, tj, to,uy, uz, va, vi, yt) {
  .Flag-#{$country}:after {
    display: block;
  }
}
