.MarkupPagerNav {
  margin: 0;
  padding: 0;
  @include res-prop("margin-top", $section-spacer, 0.5);
  @include res-prop("margin-bottom", $section-spacer, 0.5);
  display: flex;
  justify-content: center;
  list-style: none;
  font-weight: 400;
  a {
    display: block;
    color: $color-text-inverted;
  }
  li {
    &.MarkupPagerNavOn {
      span:before {
        background-color: #000;
      }
    }
  }
}

.MarkupPagerNav li {
  &:not(:last-child) {
    margin-right: 2px;
  }
}

.MarkupPagerNavSeparator,
.MarkupPagerNav span {
  padding: .5rem 0;
  min-width: 3rem;
  text-align: center;
  display: block;
  position: relative;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    height: 1px;
  }
  &:before {
    background-color: #ddd;
  }
  &:after {
    background-color: #000;
    transform: scaleX(0);
    transform-origin: 0 0;
    transition: transform .3s;
    transition-timing-function: cubic-bezier("easeInOutQuart");
  }
}

.MarkupPagerNav a {
  text-decoration: none;
  &:hover span:after {
    transform: scaleX(1);
  }
}

.MarkupPagerNav .MarkupPagerNavOn span {
  &:before {
    background-color: $color-text;
  }
  &:after {
    display: none;
  }
}

.MarkupPagerNavSeparator {
  cursor: default;
  color: color("gray-65");
}

.MarkupPagerNavNext,
.MarkupPagerNavPrevious {
  span {
    // color: transparent;
  }
  a {
    display: block;
    position: relative;
    // &:after {
    //   content: '';
    //   position: absolute;
    //   width: 24px;
    //   height: 18px;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   background: url(svg("arrow-right", "fill: none; stroke: #{$color-text}; stroke-width: 1.2"));
    // }
  }
}

.MarkupPagerNavPrevious a:after {
  transform: translate(-50%, -50%) rotate(180deg);
}

@include media-breakpoint-down(xs) {
  .MarkupPagerNav li {
    display: none !important;
    &.MarkupPagerNavPrevious,
    &.MarkupPagerNavOn,
    &.MarkupPagerNavNext {
      display: block !important;
    }
  }
}
