// @import "utilities/sizing";
.u-section-spacer-pb {
  @include res-prop("padding-bottom", $section-spacer);
}

.u-block-spacer-pb {
  @include res-prop("padding-bottom", $block-spacer);
}

.u-block-spacer-mt {
  @include res-prop("margin-top", $block-spacer);
}
