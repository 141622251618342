.Mask {
  position: fixed;
  z-index: $zindex-mask;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  overflow: hidden;
  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: none;
    transform: translate(100%,100%);
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform-origin: 0 0;
      transform: rotate(45deg) scale(3) translateY(-50%);
      background-color:  $body-bg;
    }
  }
}
