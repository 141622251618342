.Menu {
  font-family: $font-family-secondary;
  position: fixed;
  z-index: $zindex-site-header + 2;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: none;

  &-bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translate(-100%,-100%);
    // transition: transform 1.2s cubic-bezier("easeInOutQuad");
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      transform-origin: 100% 50%;
      transform: translateY(50%) rotate(45deg) scaleX(3) scaleY(5);
      background-color: color("green");
      @include loupesWebsite() {
        background-color: $color-background-gray;
      }
    }
    // html.show-menu & {
    //   transform: translate(0, 0);
    // }
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding: 0;
  }

  .Social {
    margin-top: 2rem;
  }

}

.Menu-container {
  position: absolute;
  @include res-prop("top", $site-header-height, 1.5);
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.Menu-nav {
  font-weight: 400;
  text-transform: uppercase;
  line-height: 1;
  margin-bottom: 2rem;
  @include ms-respond(font-size, 6);
  a {
    display: inline-block;
    padding-bottom:0.5rem;
    padding-top:0.5rem;
  }
  a {
    text-decoration: none !important;
    transition: color .2s;
    &:hover {
      color: $color-text-inverted;
      @include loupesWebsite() {
        color: $color-text;
      }
    }
  }
  li.is-open > a {
    color: $color-text-inverted;
    @include loupesWebsite() {
      color: $color-text;
    }
  }
  li.big > a {
    @include ms-respond(font-size, 20);
    @include loupesWebsite() {
      @include font-serif();
      @include ms-respond(font-size, 18);
    }
  }
  li.big + li:not(.big) {
    margin-top: .2em;
  }
  ul {
    margin-left: 2rem;
    display: none;
  }
}

.Menu-social {
  margin-bottom: 2rem;
}

.Menu-country {
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.Menu-country-link {
  display: inline-flex;
  align-items: center;
  span {
    margin-right: .5rem;
  }
}


