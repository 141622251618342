.Social {
  display: flex;
  flex: 0 0 auto;
  list-style-type: none;
  margin: 0;
  padding: 0;
  li:not(:last-child) {
    margin-right: 1rem;
  }
}
