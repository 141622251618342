.p-SubCategoryList {
  background: $color-background-lighter;
  color: $color-text-inverted;
  @include res-prop("padding-bottom", $block-spacer);
}

.p-SubCategoryList-item {
  @include make-col(12);
  @include reveal();
  @include res-prop("margin-bottom", $grid-gutter-widths, 2);
  &:last-child {
    margin-bottom: 0;
  }
}
