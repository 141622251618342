.HeroBanner {
  // @include block-spacer();
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  // color: $hero-banner-color;
  // text-align: center;
}

.HeroBanner-media,
.HeroBanner-image,
.HeroBanner-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.HeroBanner-media {
  @include imagePlaceholderColor();
  .desktop .HeroBanner--parallax & {
    top: -30%;
    bottom: -30%;
    height: auto;
  }
}

.HeroBanner-image {
  @extend %lazyloaded;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.HeroBanner-video {
  overflow: hidden;
  video {
    position: absolute;
    @extend %lazyloaded;
  }
}

.HeroBanner-wrapper {
  position: relative;
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  .VAlign--top & {
    justify-content: flex-start;
  }
  .VAlign--bottom & {
    justify-content: flex-end;
  }
  @include media-breakpoint-down(xs) {
    .VAlignMobile--top & {
      justify-content: flex-start;
    }
    .VAlignMobile--bottom & {
      justify-content: flex-end;
    }
  }
}

.HeroBanner-container {
  @include make-container();
  @include make-container-max-widths();
  @include reveal(false, ".HeroBanner");
}

.HeroBanner-body {
  @include grid-spacer("p", "y", 2);
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
  .HAlign--left & {
    margin-left: 0;
  }
  .HAlign--right & {
    margin-right: 0;
  }
  @include media-breakpoint-down(xs) {
    margin-right: auto !important;
    margin-left: 0 !important;
  }
}

.HeroBanner--height75 {
  .HeroBanner-wrapper {
    min-height: 75vh;
  }
}

.HeroBanner--height100 {
  .HeroBanner-wrapper {
    min-height: 100vh;
  }
}



