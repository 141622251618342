.modal {
  color: $color-text-inverted;
  text-align: left;
}

.modal-header {
  align-items: center;
}

.modal-header .close:focus {
  outline: none;
}

.modal-title {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modal-content {
  background-clip: unset;
}

.modal-body {
  $modal-header-height: "calc(2rem + 32px)";
  max-height: calc(100vh - #{$modal-header-height} - 3.5rem);
  overflow: auto;
}
