.p-Nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include media-breakpoint-down(xs) {
    margin-bottom: map_get($block-spacer, xs);
  }
}

.p-Nav-breadcrumb {
  flex: 0 0 100%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
  z-index: 2;
  // text-transform: uppercase;
  a {
    @extend .h6;
    font-family: $font-family-base;
    font-weight: 400;
    color: inherit;
    transition: opacity .2s;
    &:hover {
      text-decoration: none;
      opacity: .5;
    }
  }
  li:not(:last-child) {
    &:after {
      content: '/';
      padding: 0 .4em;
    }
  }
}

.p-Nav-title {
  @extend .h1;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
  user-select: none;
  @include loupesWebsite() {
    @include font-serif();
  }
}

.p-Nav-weight {
  margin: 0;
  padding-left: 1rem;
  display: flex;
  align-items: center;
  .Icon {
    margin-right: 0.5rem;
  }
  @include media-breakpoint-down(xs) {
    flex: 0 0 100%;
    padding: .5rem 0 0;
  }
}

.p-Nav-code {
  flex: 0 0 100%;
  margin: 0;
}

.p-Nav-toggle {
  cursor: pointer;
  // background-color: red;
  padding: 6px;
  &.is-active {
    .Icon {
      transform: rotate(180deg);
    }
  }
  &:focus {
    color: $color-link;
  }
}

.p-Nav-dropdown {
  position: relative;
  flex: 1 0 100%;
  z-index: 1000;
  pointer-events: none;
  &.is-open {
    opacity: 1;
    transform: translateY(0);
    height: auto;
    pointer-events: auto;
  }
  @include media-breakpoint-down(xs) {
    top: -0.5rem;
    margin-top: 1rem;
    height: 0;
    overflow: hidden;
    width: auto;
    margin-left: map_get($grid-container-paddings, xs) * -1;
    margin-right: map_get($grid-container-paddings, xs) * -1;
  }
  @include media-breakpoint-up(sm) {
    top: 1rem;
    height: auto;
    overflow: visible;
    opacity: 0;
    transform: translateY(10px);
    transition: opacity .3s, transform .6s;
  }
}

.p-Nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
  background-color: $color-background-light;
  border: 1px solid rgba(#000, .2); // TBFIX
  @include media-breakpoint-down(xs) {
    border-left: none;
    border-right: none;
  }
  line-height: 3;
  width: 100%;
  @include media-breakpoint-up(sm) {
    position: absolute;
    width: auto;
    min-width: 300px;
  }
  li {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(#000, .2); // TBFIX
    }
  }
  a {
    padding: 0 .5rem;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $color-text-inverted;
    font-weight: 500;
    &:hover {
      text-decoration: none;
    }
    .Icon {
      margin-left: 2rem;
    }
    .desktop &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: #000;
      opacity: .1;
      transform: scaleX(0);
      transform-origin: 0 0;
      transition: transform .5s cubic-bezier("easeOutQuart");
    }
    .desktop &:hover:after {
      transform: scaleX(1);
      transition: transform .5s cubic-bezier("easeInOutQuint");
    }
  }
}



























