.p-FamilyList {
  background: $color-background-dark;
}

.p-FamilyList-item {
  display: flex;
  flex-direction: column;
  @include make-col(6);
  @include revealStagger(2);
  @include res-prop("margin-bottom", $grid-gutter-widths);
  @include media-breakpoint-up(sm) {
    // @include make-col(6);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
    @include revealStagger(3);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(3);
    @include revealStagger(4);
  }
}
