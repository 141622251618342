.Countries {
  min-height: 100vh;
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
  }
  > * {
    position: relative;
    z-index: 2;
  }
  a {
    color: currentColor;
    font-weight: 400;
  }
}

.Countries-container {
  @include make-container();
  @include make-container-max-widths();
}

.Countries-brand {
  display: flex;
  align-items: center;
  justify-content: center;
}




.Countries-title {
  text-align: center;
  margin: 0 auto 16px;
  line-height: 1.2;
  @include media-breakpoint-down(xs) {
    width: 15em;
  }
}

.Countries-header {
  @include res-prop("height", $site-header-height);
  display: flex;
  align-items: center;
}

.Countries-main {
  padding: 8vh 0 16vh;
}

.Countries-region {
  margin-top: 2rem;
}


.Countries-regionTitle {
  // font-size: 24/16 * 1rem;
  margin-bottom: 2rem;
}

.Countries-countryList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include media-breakpoint-up(sm) {
    margin: 0 56px;

  }
}

.Countries-country {
  flex: 1 0 50%;
  margin-bottom: 1.5rem;
  font-size: calc(1rem - 1px);
  @include media-breakpoint-up(sm) {
    flex: 0 0 33.33%;
  }
  @include media-breakpoint-up(md) {
    flex: 0 0 25%;
  }
}
