.Dealers {
  @include res-prop("margin-top", $site-header-height);
  color: $color-text-inverted;
  background-color: $color-background-light;

  @include media-breakpoint-up(md) {
    background-color: $color-background-lighter;
  }

  &.BlockUI {
    &:after {
      z-index: $zindex-product-filters + 100;
    }
  }

  ul {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }
}

.Dealers-form {
  --icon-spacer: 1rem;
  --icon-size: 24px;

  position: relative;

  @include media-breakpoint-up(md) {
    max-width: 75ch;
    margin: 0 auto;
  }

  button {
    outline: none;
    color: currentColor;
    line-height: 1;
    position: absolute;
    appearance: none;
    background: none;
    top: 50%;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }

    &:focus-visible {
      border: 1px solid color('green');
    }
  }

  .Icon-svg {
    width: var(--icon-size);
    height: var(--icon-size);
  }

  .form-control {  // input
    padding-right: calc(var(--icon-size) * 2 + var(--icon-spacer) * 3);
  }
}

.Dealers-closeButton { // button
  padding: 0;
  border: none;
  right: calc(var(--icon-size) * 1 + var(--icon-spacer) * 2);
  display: none;

  .has-search &,
  .not-found & {
    display: block;
  }
}

.Dealers-locationNotFound {
  display: none;

  @include media-breakpoint-up(md) {
    max-width: 75ch;
    margin: 0 auto;
  }

  .not-found & {
    display: block;
  }
}

.Dealers-dealersNotFound {
  display: none;

  @include media-breakpoint-up(md) {
    max-width: 75ch;
    margin: 0 auto;
  }

  .dealers-not-found & {
    display: block;
  }
}

.Dealers-submitButton { // button
  right: var(--icon-spacer);
  border-radius: 50%;
  border: 1px solid black;
  padding: 1px;

  &.is-loading {
    border: 0;

    .Icon {
      opacity: 0;
    }

    &:after {
      content: '';
      position: absolute;
      border: 2px solid currentColor;
      border-bottom-color: #fff;
      border-radius: 50%;
      width: calc(26px * 0.75);
      height: calc(26px * 0.75);
      left: calc(26px * 0.25 / 2);
      top: calc(26px * 0.25 / 2);
      transform-origin: 50% 50%;
      opacity: 0.9;
      animation: 0.5s linear infinite fancybox-rotate;
    }
  }
}

.Dealers-mapWrapper {
  @include res-prop("margin-top", $block-spacer, (xs: 0.5, md: 1));

  width: 100%;
  position: relative;
  padding-bottom: calc(1 / 2 * 100%);
  background: $color-background-lighter;

  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.Dealers-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Dealers-infoWindow {
  font-family: $font-family-base;
  font-weight: 400;

  .Icon {
    display: none;
  }

  [href] {
    color: currentColor;
  }

  [href]:not([href^="mailto:"]):not([href^="tel:"]) {
    text-decoration: underline;
  }

  [data-title] {
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  [data-distance] {
    padding-top: 0.5rem;
    color: color("gray58");
  }

  [data-telephone] {
    padding-top: 0.5rem;
  }

  [data-tags] {
    padding-top: 0.5rem;

    display: flex;
    flex-wrap: wrap;

    & > * {
      margin-top: 0.5rem;
      background: color("gray85");
      line-height: 1;
      padding: 0.3em 0.6em;
      border-radius: 999px;
      font-weight: 400;

      &:not(:last-child) {
        margin-right: 0.5rem;
      }
    }
  }
}

.Dealers-infoText {
  text-align: center;
  @include res-prop("margin-top", $block-spacer, (xs: 0.75, md: 0.75));
}

.Dealers-infoList { // ul
  @include res-prop("margin-top", $block-spacer, (xs: 0.25, md: 0.5));
}

.Dealers-infoItem { // li
  @include res-prop("margin-bottom", $grid-gutter-widths);
}

.Dealers-resultsCol {
  @include res-prop("margin-top", $block-spacer, (xs: 0.5, md: 1));

  @include media-breakpoint-up(md) {
    margin-top: 0;
    @include res-prop("padding-top", $block-spacer);
  }

  min-height: 75vh;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    @include res-prop("left", $grid-gutter-widths, -0.5);
    @include res-prop("right", $grid-container-paddings, -1);
    background-color:  $color-background-light;
  }

  > * {
    position: relative;
  }
}

.Dealers-resultsContent {
  .not-found &,
  .dealers-not-found &,
  .Dealers:not(.has-search) &[data-dealers-result] {
    height: 0;
    overflow: hidden;
  }
}

.Dealers-filtersCol {
  position: relative;
  > * {
    position: relative;
  }
  // &:before {

  // }
  @include res-prop("padding-top", $block-spacer);
  // @include res-prop("margin-left", $grid-container-paddings, -0.5);
  // @include res-prop("margin-right", $grid-container-paddings, -0.5);
  @include res-prop("padding-right", $grid-gutter-widths, 1.5);
  @include media-breakpoint-down(sm) {
    padding-right: map_get($grid-gutter-widths, sm) * 0.5;
  }
  @include media-breakpoint-down(xs) {
    padding-right: map_get($grid-gutter-widths, xs) * 0.5;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: $color-background-lighter;
    @include res-prop("left", $grid-container-paddings, (xs: -0.5, sm: -0.5, lg: 0, xl: 0));
    @include res-prop("right", $grid-container-paddings, (xs: -0.5, sm: -0.5, lg: 0, xl: 0));
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  ul {
    margin: 0;
  }
}

.Dealers-titleWrapper {
  @include media-breakpoint-up(md) {
    height: calc(3rem + 2px);
  }
}

.Dealers-nav { // Copied from .p-search-aside-nav-
  @extend .h5;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  @include res-prop("margin-bottom", $block-spacer);
  li:not(:first-child) {
    margin-left: .75rem;
  }
  a {
    color: currentColor;
    font-weight: 500;
    opacity: .5;
    font-size: 90%;
  }
  .is-active {
    a {
      color: $color-link;
      opacity: 1;
      pointer-events: none;
      text-decoration: underline;
    }
  }
}

.Dealers-filters {
  @include font-size-small();

  @include res-prop("margin-top", $block-spacer);
  @include res-prop("margin-bottom", $block-spacer, (xs: 0.5, md: 1));

  @include media-breakpoint-up(md) {
    padding-top: 1px;
    @include res-prop("margin-top", $block-spacer, (xs: 0.5, md: 1));
    @include res-prop("margin-bottom", $block-spacer);
  }

  .custom-control-label {
    padding-top: 0.2em;
    @include media-breakpoint-down(xs) {
      padding-top: 0.1em;
    }
  }
}

.Dealers-title {
  font-weight: 500;
}

.Dealers-filtersList { // ul
  @include media-breakpoint-up(md) {
    border-bottom: 1px solid currentColor;
  }
}

.Dealers-filtersType { // li
  border-top: 1px solid currentColor;
}

.Dealers-filtersTitle {
  font-weight: 500;
  font-size: 15px;
  margin-top: 1rem;
}

.Dealers-filtersItem {
  font-weight: 400;
  padding: .2em 0;
  display: flex;

  &:last-child {
    padding-bottom: 1em;

    @include media-breakpoint-down(sm) {
      .Dealers-filtersType:last-child  & {
        padding-bottom: 0;
        margin-bottom: -0.4rem;
      }
    }
  }
}


.Dealers-filtersItemLink {
  color: currentColor;
  cursor: default;
  label {
    cursor: pointer;
  }
}
