.fancybox-bg {
  background: $body-bg;
  .fancybox-is-open & {
    opacity: 1;
  }
}

.fancybox-toolbar {
  // top: 16px;
  // right: 16px;
  // @include make-container();
  // @include res-prop("height", $site-header-height);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.fancybox-button {
  height: auto;
  width: auto;
  padding: 0;
  color: unset;
  background: transparent;
}

.fancybox-navigation {
  .fancybox-button {
    width: auto;
    height: auto;
    top: 50%;
    transform: translateY(-50%);
  }
  .fancybox-button--arrow_left,
  .fancybox-button--arrow_right {
    padding: 0;
  }
  .fancybox-button--arrow_left {
    // @include res-prop("left", $grid-container-paddings);
  }
  .fancybox-button--arrow_right {
    // @include res-prop("right", $grid-container-paddings);
  }

  .tablet &,
  .mobile & {
    display: none;
  }
}

.fancybox-slide--image {
  padding: 0;
  .fancybox-content {
    cursor: zoom-out;
  }
}


// full screen video
.fancybox-slide--video {
  padding: 40px 0;
  @include media-breakpoint-up(sm) {
    padding: 60px 32px;
  }
  @include media-breakpoint-up(lg) {
    padding: 64px;
  }
}

.fancybox-caption {
  color: $color-text;
  &:before {
    background-image: linear-gradient(rgba($body-bg, 0), rgba($body-bg, 0.6));
  }
}

.fancybox-product-item {
  .fancybox-content {
    padding: 0;
    width: 100%;
    max-width: 1580px;
  }
  .fancybox-slide--html {
    padding: 32px;
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }
  @include media-breakpoint-up(sm) {
    .fancybox-toolbar {
      display: none;
    }
  }
}

@keyframes fancybox-rotate {
  100% {
    transform:rotate(360deg);
  }
}
