.WishlistToggle {
  outline: none !important;
  appearance: none;
  border: 0;
  background: none;
  cursor: pointer;
  padding: 6px;
  &.is-loading {
    .Icon {
      opacity: 0;
    }
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 1rem;
      height: 1rem;
      margin-top: -0.5rem;
      margin-left: -0.5rem;
      border-radius: 50%;
      border: 2px solid currentColor;
      border-left-color: transparent;
      border-radius: 50%;
      animation: rotate .6s linear infinite;
    }
  }

  .Icon {
    color: color("gray7");
  }
}

.WishlistToggle-popover {
  // color: $color-text-inverted;
  text-align: center;
  a {
    color: inherit;
    text-decoration: underline;
  }
}
