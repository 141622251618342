.p-Search {
  @include res-prop("margin-top", $site-header-height);
  color: $color-text-inverted;
  background-color: $color-background-light;
  @include media-breakpoint-up(md) {
    background-color: $color-background-lighter;
  }
  &.BlockUI {
    &:after {
      z-index: $zindex-product-filters + 100;
      @include media-breakpoint-down(md) {
        position: fixed;
      }
    }
  }
}

.p-Search-colFilters {
  align-self: flex-start;
  position: sticky;
  top: 1rem;
  // @include res-prop("top", $site-header-height);
  @include media-breakpoint-down(md) {
    top: 0;
    position: static;
  }
}

.p-Search-colResults {
  min-height: 75vh;
  @include res-prop("padding-top", $block-spacer, (xs: 0.5, md: 1));
  @include media-breakpoint-up(md) {
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      @include res-prop("left", $grid-gutter-widths, -0.5);
      @include res-prop("right", $grid-container-paddings, -1);
      background-color:  $color-background-light;
    }
    > * {
      position: relative;
    }
  }
}

.p-Search-resultRecap {
  display: flex;
  flex-wrap: wrap;
  @include res-prop("margin-bottom", $block-spacer, (xs: 0.5, md: 1));
  @include media-breakpoint-down(sm) {
    flex-wrap: wrap;
  }
}

.p-Search-resultTitle {
  font-weight: 500;
  @include media-breakpoint-up(md) {
    margin-right: 1rem;
    flex: 0 0 auto;
  }
}

.p-Search-resultItem {
  display: flex;
  flex-direction: column;
  @include make-col(12);
  @include res-prop("margin-bottom", $grid-gutter-widths);
  @include media-breakpoint-up(sm) {
    @include make-col(6);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(4);
  }
  @include media-breakpoint-up(xl) {
    @include make-col(3);
  }
}

.p-Search-colAside {
  position: relative;
  > * {
    position: relative;
  }
  // &:before {

  // }
  @include res-prop("padding-top", $block-spacer);
  // @include res-prop("margin-left", $grid-container-paddings, -0.5);
  // @include res-prop("margin-right", $grid-container-paddings, -0.5);
  @include res-prop("padding-right", $grid-gutter-widths, 1.5);
  @include media-breakpoint-down(sm) {
    padding-right: map_get($grid-gutter-widths, sm) * 0.5;
  }
  @include media-breakpoint-down(xs) {
    padding-right: map_get($grid-gutter-widths, xs) * 0.5;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background: $color-background-lighter;
    @include res-prop("left", $grid-container-paddings, (xs: -0.5, sm: -0.5, lg: 0, xl: 0));
    @include res-prop("right", $grid-container-paddings, (xs: -0.5, sm: -0.5, lg: 0, xl: 0));
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}

.p-Search-aside-title {
  font-weight: 500;
}

.p-Search-aside-nav {
  @extend .h5;
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  @include res-prop("margin-bottom", $block-spacer);
  li:not(:first-child) {
    margin-left: .75rem;
  }
  a {
    color: currentColor;
    font-weight: 500;
    opacity: .5;
    font-size: 90%;
  }
  .is-active {
    a {
      color: $color-link;
      opacity: 1;
      pointer-events: none;
      text-decoration: underline;
    }
  }
}

.p-Search-filterToggle {


  flex: 0 0 100%;
  position: relative;


  @include media-breakpoint-up(md) {
    display: none;
  }
  .original {
    visibility: hidden;
  }
  .clone {
    position: absolute;
    top: 0;
    @include res-prop("left", $grid-container-paddings, -1);
    @include res-prop("right", $grid-container-paddings, -1);
    z-index: $zindex-product-filters-toggle;
    .p-Search-filterToggle-container {
      top: 0;
      left: 0;
      right: 0;
      transform: translate3D(0, 0, 0);
      @include res-prop("margin-left", $grid-container-paddings);
      @include res-prop("margin-right", $grid-container-paddings);
      transition: margin .2s;
    }
    .Button {
      width: 100%;
      @include button-style($color-text-inverted, $color-text-inverted, $color-text, $color-text, $color-text);
      &:before {
        opacity: 1;
      }
      // position: absolute;
      // width: unset;
      // @include res-prop("left", $grid-container-paddings);
      // @include res-prop("right", $grid-container-paddings);
    }
  }
}

.p-Search-filterToggle.is-sticky .clone .p-Search-filterToggle-container {
  margin-left: 0;
  margin-right: 0;
}

.tablet,
.mobile {
  .p-Search-filterToggle {
    &.is-sticky .clone .p-Search-filterToggle-container {
      position: fixed;
      transition: transform .3s, margin .2s;
    }
    &.no-transition .clone .p-Search-filterToggle-container {
      transition: none !important;
    }
    &.is-pinned.is-sticky .clone .p-Search-filterToggle-container {
      $headerHeight-xs : map_get($site-header-height, xs);
      $headerHeight-sm : map_get($site-header-height, sm);
      transform: translate3D(0,$headerHeight-xs,0);
      @include media-breakpoint-up(sm) {
        transform: translate3D(0,$headerHeight-sm,0);
      }
    }
  }
}

.p-Search-seoToggle {
  margin-left: auto;
  font-family: Helvetica, Arial, sans-serif;
  width: 1.2rem;
  height: 1.2rem;
  line-height: 1;
  color: $color-link;
  border: 2px solid currentColor;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  &:before {
    content: '?';
    font-size: 13px;
    font-weight: bold;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.p-Search-seoContent {
  flex: 0 0 100%;
}

.p-Search-form {
  @include res-prop(("margin-top", "margin-bottom"), $block-spacer);
  form {
    // @include grid-spacer("m", t, 1);
    position: relative;
    border-bottom: 1px solid currentColor;
    input[type="text"] {
      width: 100%;
      // font-size: 1.2rem;
      padding: .2em 32px .2em 0;
      background-color: transparent;
      border: none;
      appearance: none;
      outline: none !important;
    }
    button[type="submit"] {
      position: absolute;
      padding: 0;
      background-color: transparent;
      border: none;
      appearance: none;
      outline: none !important;
      right: 0;
      cursor: pointer;
      .Icon {
        transform: scale(0.75);
      }
      .Icon-svg {
        stroke-width: 1.9;
      }
    }
  }
}
