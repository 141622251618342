.p-Header {
  position: relative;
  @include roomForHeaderWhenIsFirstChild("margin-top");
  @include res-prop("padding-top", $block-spacer);
  @include res-prop("padding-bottom", $block-spacer);
  .fancybox-slide & {
    margin-top: 0 !important;
  }
}

.p-Header-container {
  @include revealOpacity(false, ".p-Header");
}

.p-Header-close {
  display: none;
  cursor: pointer;
  .fancybox-slide & {
    display: block;
    position: absolute;
    right:1rem;
    top:1rem;
  }
  @include media-breakpoint-down(xs) {
    display: none !important;
  }
}
