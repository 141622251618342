.HighlightList {
  text-align: center;
}

.HighlightList-body {
  @include grid-spacer("m", "b", 2);
  @include reveal();
}

.HighlightList-container {
  @include make-container();
  @include make-container-max-widths();
}

.HighlightList-row {
  @include make-row();
}
