.SpotLight {
  position: fixed;
  top: 0;
  left: 0;
  // z-index: 1000;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.SpotLight-light {
  position: fixed;
  width: 1000px;
  height: 1000px;
  top: 50%;
  left: 50%;
  // background: radial-gradient(rgba(#fff, .1), rgba(#fff, 0) 70%);
  background-image: url(../img/spotlight.png);
  background-size: 100% 100%;
  border-radius: 50%;
  opacity: 0;

  transition: opacity .6s, top .8s cubic-bezier("easeOutQuint"), left .8s cubic-bezier("easeOutQuint"), ;
  // transition: opacity .6s;
  transform: translate(-50%, -50%);
  &.is-active {
    opacity: 1;
  }
}


