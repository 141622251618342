.p-Tag {
  margin-bottom: 1rem;
}

.p-Tag-label {
  display: inline-block;
  color: $color-tag;
  background: $color-tag-background;
  text-transform: uppercase;
  font-weight: 300;
  font-family: $font-family-secondary;
  padding: 0.25em 0.5em;
}

.p-Tag--preview {
  position: absolute;
  top: 0;
  left: 0;
}

.p-Tag--header {
  position: relative;
  @include res-prop("left", $grid-container-paddings, -1);
  @include media-breakpoint-up(xl) {
    left: 0;
  }
  @include res-prop("margin-top", $block-spacer, -0.5);
}
