.Video {
  // @include block-spacer();
  @include reveal();
  // @include roomForHeaderWhenIsFirstChild();
}

.Video-container {
  @include make-container();
  @include make-container-max-widths();
}

.Video-row {
  @include make-row();
}

.Video-content,
.Video-caption {
  @include make-col-ready();
}

.Video-caption {
  @include font-size-small();
  margin-top: 1em;
  max-width: 30rem;
}

.Video-preview {
  height: 0;
  padding-bottom: 9/16 * 100%;
  position: relative;
  overflow: hidden;
  @include imagePlaceholderColor();
}

.Video-image,
.Video-video {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}

.Video-image {
  background-size: cover;
  background-position: center;
  @extend %lazyloaded;
}

.Video-video {
  overflow: hidden;
  video {
    position: absolute;
    @extend %lazyloaded;
  }
}

.Video-cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
