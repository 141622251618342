.Cta {
  // font-size: 1.2rem;
  font-weight: 400;
  border-bottom: 1px solid transparent;
  &:after {
    content: '>';
    display: inline-block;
    margin-left: .2em;
  }
  &:hover {
    text-decoration: none;
    border-bottom: 1px solid currentColor;
  }
}
