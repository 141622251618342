.Country {
  display: inline-block;
  min-width: 120px;
  position: relative;
  a {
    transition: color .2s;
    &:hover {
      text-decoration: none;
    }
    .desktop &:hover {
      color: $color-link;
    }
  }
}

.Country-heading {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0,0,0,0);
  // white-space: nowrap;
  // min-width: 120px;
}

.Country-title {
  font-weight: 400;
}

.Country-link {
  display: inline-block;
  // background: #cc0;
}

.Country-panel {
  pointer-events: auto;
  background-color: $color-background-light;
  border: 1px solid rgba(0,0,0,.2);
  padding: 11px 55px 0;
  @include media-breakpoint-down(xs) {
    position: static;
  }
  @include media-breakpoint-up(sm) {
    display: none;
    position: absolute;
    top: -12px;
    left: -56px;
    right: -56px;
    z-index: 100;
    .show-panel & {
      display: block;
    }
  }
}

.Country-panelWrapper {
  pointer-events: none;
  @include media-breakpoint-down(xs) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 100;
    .show-panel & {
      display: flex;
    }
  }
}

.Country-languageList {
  list-style-type: none;
  margin: 16px -56px 0;
  padding: 0;
  text-align: center;
  border-top: 1px solid rgba(0,0,0,.2);
}

.Contry-language {
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0,0,0,.2);
  }
  a {
    display: block;
    // font-size: 1rem;
    line-height: 3.5;
    .desktop &:hover {
      color: $color-link;
    }
  }
}


