// @mixin block-spacer($abbrev: "m", $sides: "b", $factor: 1, $spacers: $block-spacer, $important: false) {
//   @include grid-spacer($abbrev, $sides, $factor, $spacers, $important);
// }

// @mixin underline-background($color: $underline-background-color) {
//   .desktop & {
//     transform: translateZ(0);
//     transition-property: background-size;
//     transition-duration: $hover-transition-duration * 3;
//     background-image: linear-gradient($color, $color);
//     background-repeat: no-repeat;
//     background-size: 0 0.1em;
//     background-position: left bottom .05em;
//   }
//   .desktop &:hover,
//   .desktop a:hover & {
//     background-size: 100% 0.1em;
//   }
// }

@mixin button() {
  text-align: left;
  position: relative;
  z-index: 1;
  overflow: hidden;
  padding: calc(#{$btn-padding-y} + #{$btn-border-width}) calc(#{$btn-padding-x} + #{$btn-border-width});
  border-width: 0;
  outline: none !important;
  box-shadow: none !important;
  background: transparent;
  font-weight: 500;
  color: currentColor;
  &:hover {
    color: currentColor;
  }
  &:after,
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:before {
    border: $btn-border-width solid;
    z-index: -2;
    opacity: .5;
  }
  &:after {
    width: calc(100% + #{$btn-padding-y * 2} + #{$btn-border-width * 2} + #{$btn-border-width} + (#{$font-size-base} * #{$btn-line-height}));
    z-index: -1;
    transform: skew(-45deg) translateX(-100%) translateZ(0);
    transform-origin: 0 0;
    transition: transform .6s cubic-bezier("easeInOutQuint");
    background: currentColor;
    opacity: .1;
  }
  .desktop a:hover &,
  .desktop &:hover {
    &:after {
      transform: skew(-45deg) translateX(0) translateZ(0);
    }
  }
}

@mixin button-outline() {

}

@mixin font-size-small() {
  line-height: $line-height-sm;
  @each $breakpoint in map-keys($font-size-root) {
    @include media-breakpoint-up($breakpoint) {
      font-size: round(map-get($font-size-root, $breakpoint) * 0.85);
    }
  }
}

@mixin font-serif() {
  font-family: $font-family-serif !important;
  // letter-spacing: $letter-spacing-negative !important;
  font-weight: 400 !important;
  -webkit-text-stroke: .003em;
}

@mixin reveal($wait-images: false, $parent: false) {
  opacity: 0;
  transform: translateY(1rem);
  transition: transform .8s cubic-bezier("easeOutCubic"), opacity .8s;
  $loaded: "&.is-shown.is-loaded";
  $shown: "&.is-shown";
  @if($parent != false) {
    $loaded: #{$parent + ".is-shown.is-loaded &"};
    $shown: #{$parent + ".is-shown &"};
  }
  @if ($wait-images == true) {
    #{$loaded} {
      opacity: 1;
      transform: translateY(0);
    }
  } @else {
    #{$shown} {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

@mixin revealOpacity($wait-images: false, $parent: false) {
  opacity: 0;
  transition: opacity .8s;
  $loaded: "&.is-shown.is-loaded";
  $shown: "&.is-shown";
  @if($parent != false) {
    $loaded: #{$parent + ".is-shown.is-loaded &"};
    $shown: #{$parent + ".is-shown &"};
  }
  @if ($wait-images == true) {
    #{$loaded} {
      opacity: 1;
    }
  } @else {
    #{$shown} {
      opacity: 1;
    }
  }
}

@mixin remove-reveal() {
  opacity: 1;
  transform: translateY(0);
}

@mixin revealStagger($items) {
  $delay : .1s;
  @for $i from 1 through $items {
    &:nth-child(#{$items}n+#{$i}) {
      transition-delay: #{($delay * $i) - .1s};
    }
  }
}


%lazyloaded {
  transition: opacity .6s;
  opacity: 0;
  &.lazyloaded {
    opacity: 1;
  }
}

@mixin roomForHeaderWhenIsFirstChild($prop: "margin-top") {
  .Main > &:first-child {
    @include res-prop($prop, $site-header-height);
  }
}

@mixin imagePlaceholderColor() {
  background: rgba(#fff, .1);
  .Background--light &,
  .Background--lighter & {
    background: rgba(#000, .1);
  }
}

@mixin SectionPadding() {
  padding-top: 10%;
  padding-bottom: 10%;
  @media (orientation: portrait) {
    padding-top: 16%;
    padding-bottom: 16%;
  }
}

@mixin SectionItemPadding() {
  padding-bottom: 5%;
  @media (orientation: portrait) {
    padding-bottom: 8%;
  }
}

@mixin loupesWebsite($selector: "&", $extraHtmlClass: "") {
  #{"html.loupes-website" + $extraHtmlClass + " " + $selector} {
    @content;
  }
}
