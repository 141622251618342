.TextCols {
  @include roomForHeaderWhenIsFirstChild();
}

.TextCols--lessMargin.Section {
  @include res-prop("padding-top", $section-spacer, 0.5);
  @include res-prop("padding-bottom", $section-spacer, 0.5);
}

.TextCols-container {
  @include make-container();
  @include make-container-max-widths();
}

.TextCols-row {
  @include make-row();
}

.TextCols-col {
  @include make-col-ready();
  @include make-col(10);
  @include make-col-offset(1);
  @include res-prop("padding-top", $block-spacer);
  @include reveal();
  &:first-child {
    padding-top: 0;
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
  .TextCols--2 &,
  .TextCols--3 &,
  .TextCols--4 & {
    @include make-col(12);
    @include make-col-offset(0);
  }
  .TextCols--2 & {
    @include media-breakpoint-up(sm) {
      @include make-col(6);
      &:nth-child(-n+2) {
        padding-top: 0;
      }
    }
    @include media-breakpoint-up(lg) {
      @include make-col(5);
      &:nth-child(odd) {
        @include make-col-offset(1);
      }
    }
  }
  .TextCols--3 & {
    @include media-breakpoint-up(sm) {
      @include make-col(4);
      &:nth-child(-n+3) {
        padding-top: 0;
      }
    }
  }
  .TextCols--4 & {
    @include make-col(12);
    @include make-col-offset(0);
    @include media-breakpoint-up(sm) {
      @include make-col(3);
      &:nth-child(-n+4) {
        padding-top: 0;
      }
    }
  }
}

.TextCols-content {
}
