@include font-face("Univet C", "../fonts/Univet-W1G-UltLtCn",   100, normal, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-UltLtCnO",  100, italic, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-ThCn",      200, normal, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-ThCnO",     200, italic, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-LtCn",      300, normal, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-LtCnO",     300, italic, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-Cn",        400, normal, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-CnO",       400, italic, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-MdCn",      500, normal, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-MdCnO",     500, italic, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-BdCn",      700, normal, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-BdCnO",     700, italic, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-HvCn",      750, normal, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-HvCnO",     750, italic, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-BlkCn",     800, normal, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-BlkCnO",    800, italic, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-XBlkCn",    900, normal, woff2 woff ttf);
@include font-face("Univet C", "../fonts/Univet-W1G-XBlkCnO",   900, italic, woff2 woff ttf);

@include font-face("Univet",   "../fonts/Univet-W1G-UltLt",     100, normal, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-UltLtIt",   100, italic, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-Th",        200, normal, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-ThIt",      200, italic, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-Lt",        300, normal, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-LtIt",      300, italic, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-Roman",     400, normal, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-It",        400, italic, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-Md",        500, normal, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-MdIt",      500, italic, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-Bd",        700, normal, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-BdIt",      700, italic, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-Hv",        750, normal, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-HvIt",      750, italic, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-Blk",       900, normal, woff2 woff ttf);
@include font-face("Univet",   "../fonts/Univet-W1G-BlkIt",     900, italic, woff2 woff ttf);

@include font-face("made_saonara",   "../fonts/made_saonara",   400, normal, woff2 woff ttf);

// 24px
// product item preview title
// product family preview title
// product sub category preview parent title

// 30px
// download list title

// 55px
// product sub category preview title




h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6,
.h1-sans, .h2-sans, .h3-sans, .h4-sans, .h5-sans, .h6-sans,
.ha-sans, .hb-sans {
  margin-bottom: $headings-margin-bottom;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  line-height: $headings-line-height;
}

.h1-serif, .h2-serif, .h3-serif, .h4-serif, .h5-serif, .h6-serif,
.ha-serif, .hb-serif {
  margin-bottom: $headings-margin-bottom;
  @include font-serif();
}

.ha-sans,
.ha-serif {
  @include ms-respond(font-size, $ha-font-size-scale);
}

.hb-sans,
.hb-serif {
  @include ms-respond(font-size, $hb-font-size-scale);
}

h1,
.h1,
.h1-sans,
.h1-serif {
  @include ms-respond(font-size, $h1-font-size-scale);
}
h2,
.h2,
.h2-sans,
.h2-serif {
  @include ms-respond(font-size, $h2-font-size-scale);
}
h3,
.h3,
.h3-sans,
.h3-serif {
  @include ms-respond(font-size, $h3-font-size-scale);
}
h4,
.h4,
.h4-sans,
.h4-serif {
  @include ms-respond(font-size, $h4-font-size-scale);
}
h5,
.h5,
.h5-sans,
.h5-serif {
  @include ms-respond(font-size, $h5-font-size-scale);
}
h6,
.h6,
.h6-sans,
.h6-serif {
  @include ms-respond(font-size, $h6-font-size-scale);
}



// .display-1,
// .display-2,
// .display-3,
// .display-4 {
//   font-family: $display-font-family;
//   color: $display-color;
// }

// .display-1 {
//   font-weight: $display1-weight;
//   @include ms-respond(font-size, $display1-font-size-scale);
//   line-height: $display-line-height;
// }
// .display-2 {
//   font-size: $display2-size;
//   font-weight: $display2-weight;
//   @include ms-respond(font-size, $display2-font-size-scale);
//   line-height: $display-line-height;
// }
// .display-3 {
//   font-size: $display3-size;
//   font-weight: $display3-weight;
//   @include ms-respond(font-size, $display3-font-size-scale);
//   line-height: $display-line-height;
// }
// .display-4 {
//   font-size: $display4-size;
//   font-weight: $display4-weight;
//   @include ms-respond(font-size, $display4-font-size-scale);
//   line-height: $display-line-height;
// }



.font-light {
  font-weight: 200 !important;
}

.text-green {
  color: color("green");
}

.text-spaced {
  letter-spacing: $letter-spacing !important;
}

.font-serif {
  @include font-serif();
}
