.Icon {
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
}
.fancybox-button svg,
.Icon-svg {
  width: 24px;
  height: 24px;
  .Icon[class*="small"] & {
    width: 18px;
    height: 18px;
  }
  stroke: currentColor;
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  @include media-breakpoint-up(sm) {
    width: 32px;
    height: 32px;
    .Icon[class*="small"] & {
      width: 24px;
      height: 24px;
    }
  }
}

.Icon--feather,
.Icon--facebook,
.Icon--twitter,
.Icon--instagram,
.Icon--linkedin {
  .Icon-svg {
    stroke: none;
    fill: currentColor;
  }
}

.Icon--play {
  .Icon-svg {
    transform: translateX(7%);
  }
}

.Icon--star {
  .Icon-svg {
    .is-added & {
      fill: currentColor;
    }
  }
}
