.DealersModal {
  .Button {
    @include media-breakpoint-down(xs) {
      display: block;
    }
    @include media-breakpoint-up(sm) {
      min-width: 300px;
    }
  }

  &.is-loading .modal-body {
    position: relative;
    padding-bottom: 50% !important;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      border: 2px solid currentColor;
      border-bottom-color: #fff;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      margin-left: -13px;
      margin-top: -13px;
      transform-origin: 50% 50%;
      opacity: 0.9;
      animation: 0.5s linear infinite fancybox-rotate;
    }
  }
}
