.p-ItemList {
  background: $color-background-light;
  @include res-prop("padding-top", $block-spacer);
}

.p-ItemList-title {
  @extend .h3;
  @include reveal();
  color: $color-text-inverted;
  font-weight: 400;
  text-transform: uppercase;
}

.p-ItemList-item {
  display: flex;
  flex-direction: column;
  @include make-col(12);
  @include res-prop("margin-bottom", $grid-gutter-widths);
  @include media-breakpoint-up(sm) {
    @include make-col(6);
    @include revealStagger(2);
  }
  @include media-breakpoint-up(md) {
    @include make-col(4);
    @include revealStagger(3);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(3);
    @include revealStagger(4);
  }
}
