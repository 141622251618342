.TextWImageList {

}

.TextWImageList-title {
  @extend .h3;
  @include reveal();
  text-transform: uppercase;
  text-align: center;
  letter-spacing: $letter-spacing;
  max-width: 20em;
  margin: auto;
  @include res-prop("margin-bottom", $block-spacer);
}

.TextWImageList-item {
  @include reveal();
}
