.Slider {
  position: relative;
  overflow: hidden;
  .swiper-container {
    overflow: visible;
  }
}

.Slider-container {
  @include make-container();
  @include reveal(false, ".Slider");
  cursor: grab;
}

.Slider-title {
  @extend .h3;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: $letter-spacing;
  max-width: 20em;
  margin: auto;
  @include res-prop("margin-bottom", $block-spacer);
}


.Slider-slide {
  flex: 0 0 auto;
  width: auto;
}

.Slider-slide--image {
  img {
    @extend %lazyloaded;
    // pointer-events: none;
    width: auto;
    height: 70vh;
    max-height: 550px;
    @media (orientation: portrait) {
      height: 60vh;
    }
    @include media-breakpoint-up(lg) {
      max-height: 600px;
    }
  }
  & + & {
    margin-left: 12px;
  }
}

.Slider-slide--text {
  $base: 45vw;
  $max: 20rem;
  $large: 1.6;
  $portrait: 1.2;
  $sm: 0.7;
  box-sizing: content-box;
  padding: 0 6vw;
  &:first-child {
    padding-left: 0;
  }
  align-self: center;
  width: $base;
  max-width: $max;
  @include media-breakpoint-up(sm) {
    width: $base * $sm;
  }
  &.Slider-slide--large {
    padding: 0 4vw;
    width: $base * $large;
    max-width: $max * $large;
    @include media-breakpoint-up(sm) {
      width: $base * $large * $sm;
    }
  }
  @media (orientation: portrait) {
    width: $base * $portrait;
    @include media-breakpoint-up(sm) {
      width: $base * $portrait * $sm;
    }
    &.Slider-slide--large {
      width: $base * $portrait * $large;
      @include media-breakpoint-up(sm) {
        width: $base * $portrait * $large * $sm;
      }
    }
  }
}


.Slider-scrollbar.swiper-scrollbar {
  margin-left: auto !important;
  margin-right: auto !important;
  width: 80%;
  @include media-breakpoint-up(sm) {
    width: 50%;
  }
}

.Slider-navigation {
  position: absolute;
  top: 0;
  bottom: 0;
  @include res-prop(("left", "right"), $grid-container-paddings, -1);
}

.Slider-button {
  .mobile & {
    display: none;
  }
  position: absolute;
  top: 50%;
  transition: transform .6s cubic-bezier("easeOutQuint");
  &[data-slider-prev] {
    left: 0;
    transform: translate(-100%, -50%);
  }
  .Slider:hover &[data-slider-prev]:not(.is-disabled) {
    transform: translate(50%, -50%)
  }
  &[data-slider-next] {
    right: 0;
    transform: translate(100%, -50%)
  }
  .Slider:hover &[data-slider-next]:not(.is-disabled) {
    transform: translate(-50%, -50%)
  }
}
