.Highlight {
  @include make-col-ready();
  @include grid-spacer("m", "b");
  @include reveal();
  color: $color-text;
  text-align: center;
  &:not(.Highlight--double) {
    @include media-breakpoint-up(sm) {
      @include make-col(6);
    }
  }
}

.Highlight--inverted {
  color: $color-text-inverted;
}

.Highlight-link {
  color: inherit;
  display: block;
  position: relative;
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.Highlight-figure {
  @include imagePlaceholderColor();
}

.Highlight-figure,
.Highlight-image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.Highlight-image {
  background-size: cover;
  background-position: center;
}

.Highlight-image--mobile {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.Highlight-image--mobile + .Highlight-image--default {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.Highlight-container {
  position: relative;
  display: flex;
  &:before {
    content: "";
    width: 1px;
    margin-left: -1px;
    height: 0;
    padding-top: 120%;
    @include media-breakpoint-up(sm) {
      padding-top: 100%;
      .Highlight--double & {
        padding-top: 500 / 1380 * 100% * 2;
      }
    }
  }
  .Highlight--double & {
    @include media-breakpoint-up(sm) {
      align-items: center;
      width: 50%;
    }
  }
  .Highlight--endTextAlign.Highlight--double & {
    @include media-breakpoint-up(sm) {
      margin-left: 50%;
    }
  }
}

.Highlight-content {
  margin: 0 auto;
  max-width: 32rem;
  @include grid-spacer("p", "", 2);
  .Highlight--endTextAlign & {
    align-self: flex-end;
  }
  .Highlight--endTextAlign.Highlight--double & {
    @include media-breakpoint-up(sm) {
      align-self: center;
    }
  }
  @include media-breakpoint-down(xs) {
    align-self: flex-end !important;
  }
}

.Highlight-body {
  margin-bottom: 1rem;
}

.Highlight-title {
  text-transform: uppercase;
}
